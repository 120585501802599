 
export const nav_items = [
  
    {
        item:"Blogs",
        onclick:"/blogs"   
    },
    {
        item:"About us",
        onclick:"/about-us"   
    },
  
]
export const brands = [
    {
   brand:"Audi",
    onclick:"/audi"
   },
   {
    brand:"BMW",
    onclick:"/bmw"
   },
   {
    brand:"Mercedes Benz",
    onclick:"/mercedes"
   },
   {
    brand:"Volkswagen",
    onclick:"/volkswagen"
   },
   {
    brand:"Other brands",
    onclick:"/car-tuning-brands"
   },
] 
 