
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ArticleContainer, BannerContainer,  SectionContainer, SectionContainerArticle } from '../../components/sections/Container'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
 import {InstaImage, InstaImageDesktop} from './components/InstaImage';
import Youtube from './components/Youtube';
import DateShare from './components/DateShare';
import { SecondaryButton } from '../../components/buttons/secondary-button/SecondaryButton';
import { secondary } from '../../data/components/button';
import {RelatedArticle, RelatedArticleMob} from './components/RelatedArticle';
import { FloatingButton } from '../../components/buttons/floating-button/FloatingButton';
import Footer from '../../components/footer/Footer'
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import GTM from '../../googletagmanager/GTM';
import { ArticleHelmet } from '../../preclicktags/PreClick';
import { Helmet } from 'react-helmet';

const Article = () => {
  const [post, setPost] = useState(null);
  const { id, } = useParams();   
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/posts/${id}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);  
        setPost(data);
      })
      .catch(err => console.error(err));
  }, [id]);   

  const [randoms, setRandoms ] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/random?currentPostId=${id}`);
        console.log(response.data); 
        setRandoms(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchPosts();
  }, [id]);



  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <GTM/>
      <ArticleHelmet/>
       <Helmet>
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={post.title} />
                <meta property="og:description" content={post.mainParagraph} />
                <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} />
            </Helmet>
<DefaultNav/>
<FloatingButton/>
<BannerContainer>
 <div className=''>
 <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className='w-full h-96 lg:max-h-96 object-cover lg:rounded-[16px]'/> 
  </div>
 </BannerContainer>
 
 <SectionContainer>
 <ArticleContainer>
 <h1 className='lg:w-3/4'>{post.title}</h1>
 <DateShare createdAt={post.createdAt} articleUrl={window.location.href} articleTitle={post.title} />
 <p className='pt-2 lg:pt-4'>  {post.mainParagraph}</p>
</ArticleContainer>
 </SectionContainer>
  <div className='block lg:hidden'>
  <InstaImage images={post.images?.map((image, index) => `${process.env.REACT_APP_API_URL}/${image}`)} altforImages={post.altTextSecondary} />
  </div>
  <div className='lg:block hidden'>
  <InstaImageDesktop images={post.images?.map((image, index) =>`${process.env.REACT_APP_API_URL}/${image}`)}  altforImages={post.altTextSecondary} />
  </div>
  {post.subParagraph &&
 <SectionContainer>
 <ArticleContainer>
 <p className='mt-2 lg:mt-4'> {post.subParagraph}</p>
  </ArticleContainer>
 </SectionContainer> }
 {/**youtube */}
 {post.youtubeVideo && <Youtube videoLink={post.youtubeVideo}/>}

{post.sections && post.sections.length > 0 && post.sections.map((section, index) => (
  <SectionContainerArticle>
  <ArticleContainer>
  <div key={index}>
    <h3>{section.subTitle}</h3>
    <p className='mt-2'>{section.paragraph}</p>
    {section.sectionImage &&
    <div className='h-52 md:h-auto  object-cover'>
    <img src={`${process.env.REACT_APP_API_URL}/${section.sectionImage}`} alt={section.subTitle} className='w-full mt-6 h-full md:h-auto lg:h-30 object-cover  rounded-[16px]'/>
    </div>}
  </div>
  </ArticleContainer>
 </SectionContainerArticle>
))}
 <SectionContainer>
 <DateShare  createdAt={post.createdAt} />
 </SectionContainer>

 
<ArticleContainer> 
    <div className='mx-auto mt-5 flex justify-center'>
 <SecondaryButton
 buttonText={secondary[0].buttonText}
 redirect="/contact-us"/>  
 </div>
           </ArticleContainer>
 
<SectionContainer>
<ArticleContainer>
<h3>You might like also</h3>
{randoms.map((random) => (
        <RelatedArticle key={random._id} post={random} />
      ))}

         <div className='mt-6  lg:mt-8 block md:hidden  '>
         <Swiper
                      spaceBetween={0}
                      modules={[Pagination]}
                      breakpoints={{
                        0: {
                          slidesPerView: 1.2,
                        },
                        480: {
                          slidesPerView: 1.8,
                        },
                        615: {
                          slidesPerView: 2.1,
                          centeredSlides: false,
                        },
                       992: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                      }}
                    >
 {randoms.map((random) => (
<SwiperSlide className='mb-2'>
<RelatedArticleMob key={random._id} post={random}/>
</SwiperSlide>
 ))}
</Swiper>
 </div>
 
</ArticleContainer>
 </SectionContainer>
 <Footer/>
          </>

  )
}

export default Article
