import React, { useState, useEffect } from 'react';
import { ArticleContainer  } from '../../../components/sections/Container'
 import { DateCreated } from './DateShare';
import { truncateText } from '../../../utils/truncateText';
import slugify from 'slugify';


export const RelatedArticle = ({post}) => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClick  = () => {
    setRedirectTo(`/blogs/article/${slugify(post.title, { lower: true })}/${post._id}`);
  };
  return (
 <>
 
 <ArticleContainer>
          <div
          className='mt-3 lg:mt-8 hidden md:block relative'
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        >      
        <div >
        <div className='flex flex-row bg-normalWhite box-shadow rounded-[16px]  '>
          <div className='w-4/12'>
          <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className='rounded-s-[16px] object-contain h-auto  '/>
          </div>
          <div className='w-8/12 p-3 my-auto'>
            <h5>{truncateText(post.title, 60)}</h5>
            <p className='text-dasGrayDark lg:my-3'>{truncateText(post.mainParagraph, 105)}</p>
            <div className='text-dasGrayDark '>
            <DateCreated createdAt={post.createdAt}   />
            </div>
          </div>
        </div>
      </div>
      </div>
    </ArticleContainer>
 
 </>
  )
}
 
export const RelatedArticleMob = ({post}) => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClickMob  = () => {
    setRedirectTo(`/blogs/article/${slugify(post.title, { lower: true })}/${post._id}`);
  };
  
    return (
        <>
      <div   onClick={handleClickMob}>  
       <div className='flex flex-col mx-3 sm:h-96  h-96  bg-normalWhite box-shadow rounded-[16px]'>
       <div className='w-auto h-auto'>
       <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className='rounded-t-[16px] object-contain h-auto  '/>
       </div>
       <div  className='p-3'>
           <h5>{truncateText(post.title, 30)}</h5>
           <p className='text-dasGrayDark py-2'>{truncateText(post.mainParagraph, 50)}</p>
           <p className='text-dasGrayDark'>    <DateCreated createdAt={post.createdAt}   /></p>
       </div>
       </div>
       </div>
       </>
         )
}

 
