import { RiPhoneLine } from "react-icons/ri";
import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { BsArrowRightShort } from "react-icons/bs";

export const reachus = [
  {
    icon_location: <HiOutlineLocationMarker />,
    address:
      "Unit A - شارع - ١٥ - Al Quoz Industrial Area 3 - Dubai",

    icon_phone: <RiPhoneLine />,
    icon_phone2: <HiDevicePhoneMobile />,
    contact: "+971 50 752 6677",
    contact2: " +971 50 515 6697",
    icon_mail: <HiOutlineMail />,
    icon:<BsArrowRightShort/>,
    mail: "dastuning.ae@gmail.com",
  },
];
