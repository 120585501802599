import React from 'react'
import { BrandsContainer, SectionContainer } from '../../../components/sections/Container'

const GridSection = ({ title1, description1, image1, alt1, title2, description2, image2, alt2,  }) => {
  return (
    <>
    <SectionContainer>
    <BrandsContainer>
    
    <div className='flex flex-col lg:flex-row   lg:gap-x-6 gap-x-6 justify-between'>
    <div className='lg:w-5/12 w-full   '>
        <img src={image1} alt={alt1} 
        className='s:h-80 sm:h-80 box-shadow-main-banner lg:h-full object-cover w-full justify-center rounded-[16px]'/>
        </div>
        <div className='lg:w-7/12 md:pt-8 pt-6 lg:pt-0  w-full'>
        <h3>{title1}</h3>
        <p className='pt-2 lg:pt-4'>{description1}</p>
        </div>
    </div>
    </BrandsContainer>
      </SectionContainer>
      
          <SectionContainer>
          <BrandsContainer>
    <div className='flex flex-col lg:flex-row lg:gap-x-6 gap-x-6 justify-between'>
    <div className='lg:w-5/12 w-full   '>
        <img src={image2} alt={alt2} 
        className='s:h-80 sm:h-80 box-shadow-main-banner lg:h-full object-cover w-full justify-center rounded-[16px]'/>
        </div>
        <div className='lg:w-7/12 md:pt-8 pt-6 lg:pt-0  w-full'>
        <h3>{title2}</h3>
        <p className='pt-2 lg:pt-4'>{description2}</p>
        </div>
    </div>
      </BrandsContainer>
      </SectionContainer>
      </>
  )
}

export default GridSection
