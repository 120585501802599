import React from 'react'
 
export const Torque = ({data}) => {
  return (
  <>
 <h4 className='my-3 lg:my-6'>Torque (NM)</h4>

  <div className='p-4 lg:p-3 my-2 lg:w-8/12 box-shadow rounded-[16px] bg-normalWhite text-black'>
  {data.map((val, index) => (
 <div
 className="flex justify-between text-decoration-none  py-2 ">
 <div className="flex flex-row">
<h3 >{val.icon}</h3>
   <h6 className="ps-2 w-11/12 md:w-full">{val.text}</h6>
 </div>
 <div className='flex items-center  my-auto '>
 <p style={{ color: index === 0 ? 'red' : 'green' }}>{val.value}</p>
  </div>
</div>

    ))}

      </div>
  
  </>
  )
}

 
export const TorqueHome = ({data}) => {
  return (
  <>
 <h4 className='my-3 lg:my-6'>Torque (NM)</h4>

  <div className='p-4 lg:p-3 my-2 lg:w-full box-shadow rounded-[16px] bg-normalWhite text-black'>
  {data.map((val, index) => (
 <div
 className="flex justify-between text-decoration-none  py-2 ">
 <div className="flex flex-row">
<h3 className='my-auto'>{val.icon}</h3>
   <h6 className="ps-2 w-10/12 md:w-full">{val.text}</h6>
 </div>
 <div className='flex items-center  my-auto '>
 <p style={{ color: index === 0 ? 'red' : 'green' }}>{val.value}</p>
  </div>

</div>

    ))}

      </div>
  
  </>
  )
}