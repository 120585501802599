import { useState } from "react";
 import  {BsWhatsapp, BsXLg, BsTelephone,   } from "react-icons/bs";
 
 export const FloatingButton = () => {
    const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed z-10  bottom-2 md:bottom-10  right-2">
      <button
        className="bg-dasBlack  text-dasRed font-bold p-2 py-4 rounded-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <h5 className="px-6" style={{fontSize:"16px"}}><BsXLg/></h5> : 
        
        <h5  className="flex flex-row gap-x-2 my-auto px-1">
          <div className="my-auto" style={{fontSize:"16px"}}><BsTelephone/></div>
  
          <div className="border-l h-4 mx-1">
          </div>
          <div className="my-auto" style={{fontSize:"16px"}}> <BsWhatsapp /></div> </h5>}
      </button>
      {isOpen && (
        <div className="absolute bottom-[calc(46px+1rem)] right-0 flex flex-col space-y-4 items-end">
          <a
            href="tel:+971-50-515-6697"
            className="bg-dasBlack  text-dasRed font-bold  p-4 rounded-full"
          >
          <h5 className="px-4" style={{fontSize:"16px"}}><BsTelephone/></h5>
          </a>
          <a
            href="https://wa.me/message/UIUAMHPZQRFAC1"
            className="bg-dasBlack  text-dasRed font-bold  p-4 rounded-full"
          >
           <h5 className="px-4" style={{fontSize:"16px"}}><BsWhatsapp/></h5>
          </a>
        </div>
      )}
    </div>
  );
}
 
export const FloatingButtonMob = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed z-10  bottom-2 md:bottom-10  right-2 block lg:hidden">
      <button
        className="bg-dasBlack  text-dasRed font-bold p-2 py-4 rounded-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <h5 className="px-6" style={{fontSize:"16px"}}><BsXLg/></h5> : 
        
        <h5  className="flex flex-row gap-x-2 my-auto px-1">
          <div className="my-auto" style={{fontSize:"16px"}}><BsTelephone/></div>
  
          <div className="border-l h-4 mx-1">
          </div>
          <div className="my-auto" style={{fontSize:"16px"}}> <BsWhatsapp /></div> </h5>}
      </button>
      {isOpen && (
        <div className="absolute bottom-[calc(46px+1rem)] right-0 flex flex-col space-y-4 items-end">
          <a
            href="tel:+971-50-515-6697"
            className="bg-dasBlack  text-dasRed font-bold  p-4 rounded-full"
          >
          <h5 className="px-4" style={{fontSize:"16px"}}><BsTelephone/></h5>
          </a>
          <a
            href="https://wa.me/message/UIUAMHPZQRFAC1"
            className="bg-dasBlack  text-dasRed font-bold  p-4 rounded-full"
          >
           <h5 className="px-4" style={{fontSize:"16px"}}><BsWhatsapp/></h5>
          </a>
        </div>
      )}
    </div>
  );
}