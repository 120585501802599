import { BsSpeedometer2, BsWrench,  BsArrowUpCircle, BsPlusSlashMinus,   } from "react-icons/bs";

const baseData = [
    {
        icon: <BsSpeedometer2/>,
        text: "Original",
        value: ""
    },
    {
        icon: <BsWrench/>,
        text: "Tuned",
        value: ""
    },
    {
        icon: <BsPlusSlashMinus/>,
        text: "Difference",
        value: ""
    },
    {
        icon: <BsArrowUpCircle/>,
        text: "Increased by",
        value: ""
    }
]
//Mercedes
const valuesForMercedesPower = ["469", "570", "+101", "+21%"];
const valuesForMercedesTorque = ["700", "850", "+150", "+21%"];
export const MercedesPower = baseData.map((item, index) => {
    return { 
        ...item, 
        value: valuesForMercedesPower[index]
    }
});

export const MercedesTorque = baseData.map((item, index) => {
    return { 
        ...item, 
        value: valuesForMercedesTorque[index]
    }
});
export const graphForMercedes = 
[{img:"./Images/graphs/das-tuning-dubai-mercedes-c63s-tuning-results-graph.png",
alt:"Experience the impressive performance gains of our Mercedes C63S tuning project at Das Tuning, Dubai's premier car tuning company. Compare the stock and tuned results graph."}]
//Audi
const valuesForAudiPower = ["394", "470", "+76", "+19%"];
const valuesForAudiTorque = ["480", "620", "+140", "+29%"];
export const AudiPower = baseData.map((item, index) => {
    return { 
        ...item, 
        value: valuesForAudiPower[index]
    }
});

export const AudiTorque = baseData.map((item, index) => {
    return { 
        ...item, 
        value: valuesForAudiTorque[index]
    }
});
export const graphForAudi = 
[{img:"./Images/graphs/audi-rs3-tuning-performance-graph-dubai.png",
alt:"Experience the power unleashed with our Audi RS3 tuning services as depicted in the performance graph. Das Tuning, the premier car tuning company in Dubai, enhances your Audi's performance to new heights."}]
//Volkswagen
const valuesForVolkswagenPower = ["261", "350", "+89", "+34%"];
const valuesForVolkswagenTorque = ["320", "460", "+140", "+43%"];
export const VolkswagenPower = baseData.map((item, index) => {
    return { 
        ...item, 
        value: valuesForVolkswagenPower[index]
    }
});

export const VolkswagenTorque = baseData.map((item, index) => {
    return { 
        ...item, 
        value: valuesForVolkswagenTorque[index]
    }
});
export const graphForVolkswagen = 
[{img:"./Images/graphs/das-tuning-dubai-volkswagen-gti-clubsport-tuning-results-graph.png",
alt:"Experience the impressive transformation of our Volkswagen GTI Clubsport through our expert car tuning services at Das Tuning, Dubai. Compare the stock and tuned results graph."}]
//Bmw
const valuesForBmwPower = ["405", "500", "+95", "+23%"];
const valuesForBmwTorque = ["550", "680", "+130", "+23%"];
export const BmwPower = baseData.map((item, index) => {
    return { 
        ...item, 
        value: valuesForBmwPower[index]
    }
});

export const BmwTorque = baseData.map((item, index) => {
    return { 
        ...item, 
        value: valuesForBmwTorque[index]
    }
});
export const graphForBMW = 
[{img:"./Images/graphs/bmw-m2-results-performance-graph-dubai.png",
alt:"Witness the impressive results of Das Tuning's car tuning services on a stunning green BMW M2. Enhance your car's performance with our expert tuning solutions and see the remarkable difference. Check out the performance graph showcasing the power gains."
}]

//other brands
const valuesForOtherBrandPower = ["250", "315", "+65", "31"];
const valuesForOtherBrandTorque = ["350", "415", "+75", "41"];
export const graphForOtherBrand = 
[{img:"./Images/graphs/other-brands.png",
alt:""}]

export const otherBrandPower = baseData.map((item, index) => {
    return { 
        ...item, 
        value: valuesForOtherBrandPower[index]
    }
});

export const otherBrandTorque = baseData.map((item, index) => {
    return { 
        ...item, 
        value: valuesForOtherBrandTorque[index]
    }
});