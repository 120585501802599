import React, { useState, useEffect } from 'react';
import {  SectionContainer } from '../../components/sections/Container'
import { featured_brands, featured_brands_4, other_brands } from '../../data/home'
import './animations.css'
const FeaturedBrands = () => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClickOpen = () => {
    setRedirectTo("/car-tuning-brands");
  };
 
  function handleClick(path) {
    setRedirectTo(path);
  }
  return (
    <SectionContainer >
 
       <article className='overflow-x-hidden      '>
        <h2 class="relative inline-block">{ featured_brands[0].title}</h2>
        <div class="border-b-2 border-dasBlack  lg:w-1/12 w-2/6 sm:w-2/12"></div>
        <p className='py-3 lg:py-3'>{featured_brands[0].desc}</p>
    </article>
 
<article className='py-2 grid md:grid-cols-4 grid-cols-2 gap-4 sm:gap-6 md:gap-x-4 xl:gap-x-6'>
  {featured_brands_4.map((item)=>(
<div class="text-center ">
<div onClick={() => handleClick(item.onclick)}
 className=' text-center  rounded-[16px] box-shadow justify-center  flex align-middle md:h-44 h-40 xl:h-52 sm:h-48 '>
  <div className='w-5/6  mx-auto my-auto cursor-pointer'>
  <img src={item.img} alt={item.alt} className='mx-auto w-2/6 sm:w-3/12 md:w-2/6 '/> 
  <h3 className='mx-auto w-6/6 mt-3'>{item.name}</h3>
  </div>
  </div>
</div>
))}
</article>
     
     <article className='py-4 text-center '>
     <div class='slider-anim text-center  rounded-[16px] box-shadow p-4 lg:p-10'>
      <div class='container-anim '>
        {other_brands.map((item)=>(
      <div><img src={item.img} alt={item.alt} className='p-2'/></div>
        ))}
      </div>
    </div>
    <button className=' text-dasRed pt-5 lg:pt-7' onClick={handleClickOpen}><h6>Other brands</h6></button>
      
     </article>
 
    </SectionContainer >
  )
}

export default FeaturedBrands
