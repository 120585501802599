import {  BsChevronCompactDown, BsStarFill  } from "react-icons/bs";

export const hero = [{
    banner_desk:"./Images/home/hero/das-tuning-red-porsche-911-gt3-car-tuning.jpg",
    banner_mob:"./Images/home/hero/das-tuning-red-porsche-911-gt3-car-tuning-mob.png",
    text1:"Elevate Your Ride   ",
    text2:"with Expert Car Tuning in Dubai!",
    text3:"Enhance the performance, speed, and style of your vehicles with our bespoke car tuning services.",
   alt:"Red Porsche 911 GT3 showcasing high-performance car tuning services by Das Tuning, enhancing power, speed, and style.",
    icon:<BsChevronCompactDown/>
}]
export const introduction =[{
    title:"Introduction",
    desc:"DAS Tuning is a premier automotive specialist that focuses on bespoke software and hardware modifications for high end sports and luxury vehicles. We are certified specialists in BMW, Mercedes-Benz, Audi, Volkswagen, Porsche, Range-Rover, Bentley and Rolls-Royce. With over 30 years experience in the automotive industry, you can trust us to deliver the highest quality services from bespoke exhausts systems to turbocharger upgrades and custom dyno tuning.",
    img:"./Images/home/introduction/red-mclaren-570s-tuning-das-tuning.png",
    alt:"Enhance your driving experience with our car tuning services at Das Tuning. Red McLaren 570S showcasing the perfect blend of power, performance, and style."
}]
export const introduction_cards =[
    {
        icon:"1",
        title:"Request",
        desc:" Request a tune up today and get tailored car tuning services from our team of professionals in Dubai and the UAE. "

    },
    {
        icon:"2",
        title:"Tuning",
        desc:"Our team uses expert techniques for car tuning in Dubai and the UAE. We guarantee top results."

    },
    {
        icon:"3",
        title:"Go faster",
        desc:" Your car will be more powerful, faster and highly responsive after tuning. Experience the difference yourself!"

    },
 
]
export const featured_brands=[{
    title:"Featured Brands",
    desc:"  At Das Tuning, we have a team of certified and trained individuals who specialize in providing bespoke software and hardware modifications for automotive brands in Dubai and the UAE. ",
}]
export const featured_brands_4=[
    {
    img:"./Images/automakers-logo/audi-car-tuning-das-tuning.png",
    alt:"Experience the ultimate Audi car tuning services at Das Tuning. The iconic Audi logo representing precision, performance, and luxury.",
    name:"Audi",
    button:"Learn more",
    onclick:"/audi" 
},
{
    img:"./Images/automakers-logo/bmw-logo-car-tuning-das-tuning.png",
    alt:"Unlock the full potential of your BMW with exceptional car tuning services at Das Tuning. Immerse in the iconic BMW logo symbolizing performance, elegance, and innovation.",
    name:"BMW",
    button:"Learn more",
    onclick:"/bmw" 
},
{
    img:"./Images/automakers-logo/mercedes-logo-car-tuning-das-tuning.png",
    alt:"Experience the epitome of luxury and performance with Mercedes car tuning services by Das Tuning. Embrace the iconic Mercedes logo, a symbol of elegance, power, and craftsmanship.",
    name:"Mercedes ",
    button:"Learn more",
    onclick :"/mercedes" 
},
{
    img:" ./Images/automakers-logo/volkswagen-car-tuning-das-tuning.png",
    alt:"Unlock the full potential of your Volkswagen with professional car tuning services by Das Tuning. Embrace the iconic Volkswagen logo, a symbol of reliability, innovation, and driving pleasure.",
    name:"Volkswagen",
    button:"Learn more",
    onclick:"/volkswagen" 
},
]
export const other_brands = [
    {
        img:"./Images/automakers-logo/aston-martin-logo.png",
        alt:"Aston Martin logo - Embrace the elegance and power of the renowned British luxury car brand."
},
{
        img:"./Images/automakers-logo/bentley-logo-das-tuning.png",
        alt:"Embrace the Bentley car tuning excellence at Das Tuning. Discover the iconic Bentley logo, symbolizing luxury, performance, and craftsmanship."
},
{
        img:"./Images/automakers-logo/ferrari-logo-car-tuning-das-tuning.png",
        alt:"Indulge in the unrivaled Ferrari car tuning expertise at Das Tuning. Experience the legendary Ferrari logo, a symbol of passion, speed, and automotive excellence."
},
{
      img:"./Images/automakers-logo/jaguar-logo-car-tuning-das-tuning.png",
      alt:"Indulge in extraordinary Jaguar car tuning services at Das Tuning. Behold the captivating Jaguar logo, symbolizing elegance, power, and sophistication."
},
{
    img:"./Images/automakers-logo/lamborghini-logo-das-tuning.png",
    alt:"Embark on a thrilling journey of Lamborghini car tuning expertise at Das Tuning. Witness the captivating Lamborghini logo, an emblem of power, style, and exhilaration."
},
{
    img:"./Images/automakers-logo/land-rover-logo-das-tuning.png",
    alt:"Indulge in exceptional Land Rover car tuning services at Das Tuning. Behold the distinguished Land Rover logo, a symbol of adventure, sophistication, and off-road capability."
},
{
    img:"./Images/automakers-logo/maserati-logo-car-tuning-das-tuning.png",
    alt:"Indulge in unrivaled Maserati car tuning services at Das Tuning. Witness the captivating Maserati logo, epitomizing elegance, power, and sophistication."
},
{
    img:"./Images/automakers-logo/mclaren-logo-car-tuning-das-tuning.png",
    alt:"Explore the pinnacle of car tuning with our exceptional McLaren services at Das Tuning. Behold the iconic McLaren logo, symbolizing cutting-edge technology and exhilarating performance."
},
{
    img:"./Images/automakers-logo/mini-logo-car-tuning-das-tuning.png",
    alt:"Discover exceptional Mini car tuning services at Das Tuning. Embrace the iconic Mini logo, a symbol of style and exhilaration."
},
{
    img:"./Images/automakers-logo/porsche-logo-car-tuning-das-tuning.png",
    alt:"Discover the art of Porsche car tuning excellence at Das Tuning. Immerse yourself in the timeless allure of the iconic Porsche logo, symbolizing precision and exhilaration."
},
{
    img:"./Images/automakers-logo/rolls-royce-logo-car-tuning-das-tuning.png",
    alt:"Experience the epitome of luxury with our Rolls-Royce car tuning services at Das Tuning. Discover the iconic Rolls-Royce logo, symbolizing timeless elegance and unparalleled craftsmanship"
},
{img:" ./Images/automakers-logo/lancia-logo-car-tuning-das-tuning.png",
alt:"Discover the iconic Lancia logo representing performance and style at Das Tuning, Dubai's premier car tuning company ",
},
{
    img:"./Images/automakers-logo/bentley-logo-das-tuning.png",
    alt:"Embrace the Bentley car tuning excellence at Das Tuning. Discover the iconic Bentley logo, symbolizing luxury, performance, and craftsmanship."
},
{
    img:"./Images/automakers-logo/ferrari-logo-car-tuning-das-tuning.png",
    alt:" Indulge in the unrivaled Ferrari car tuning expertise at Das Tuning. Experience the legendary Ferrari logo, a symbol of passion, speed, and automotive excellence."
},

]
export const tuning_result = [
{
title:"Tuning results",
desc:"Enhance engine power, speed, acceleration, and overall driving experience. At Das tuning, we provide exceptional tuning results with our car tuning services. Tune up your car today and take your car to the next level!"
},
]
export const tuning_result_images=[{
 
    alt1:"Experience the pinnacle of luxury and performance with our car tuning services for white Mercedes C63S vehicles at Das Tuning. Elevate your driving experience with our expert modifications and enhancements."  ,
 
    alt2:"Unleash the power and style of your red BMW M4 with our exceptional car tuning services at Das Tuning. Transform your driving experience with our expert modifications and enhancements." ,
  
    alt3:"Experience the ultimate car tuning services for your white Volkswagen GTI Clubsport at Das Tuning. Enhance performance and style with our expert modifications and upgrades."  , 
  
    alt4:"Unleash the power and style of your matte grey Audi RS3 with our premium car tuning services at Das Tuning. Experience the thrill of expert modifications and performance enhancements." , 
  
    alt5:"Experience the ultimate car tuning services for your white Volkswagen GTI Clubsport at Das Tuning. Enhance performance and style with our expert modifications and upgrades."  , 
}]
export const start_tuning = [{
    title:"Lets start tuning",
    desc:"Book your car tuning services today and boost your car performance with the help of our expert automobile technicians. let's start the journey towards a truly exceptional driving experience.",
}]
export const start_tuning_images = [

 
 {
    src: "./Images/home/start-tuning/das-tuning-dubai-red-bmw-m4-back-tuning-shop.png",
    alt: "Discover the art of car tuning at Das Tuning, Dubai, as showcased by the striking red BMW M4 at a tuning shop."
  },
  {
    src: "./Images/home/start-tuning/das-tuning-dubai-orange-renault-megane-rs-hood-open-tuning.png",
    alt: "Experience the precision of car tuning services at Das Tuning, Dubai, as our experts tune the powerful orange Renault Megane RS with its hood open."
  },
  {
    src: "./Images/home/start-tuning/das-tuning-dubai-white-mercedes-c63s-car-tuning-road-performance.png",
    alt: "Experience the ultimate performance on the road with our customized car tuning services for the white Mercedes C63S at Das Tuning in Dubai. Unleash the power of precision engineering!"
  },
  {
    src: "./Images/home/start-tuning/das-tuning-dubai-matte-grey-mclaren-570s-rear-tuning-shop.png",
    alt: "Enhance your driving experience with our professional car tuning services for the matte grey McLaren 570S at the Das Tuning shop in Dubai."
  },
]
export const start_tuning_images_mob = [
 
    {
        src: "./Images/home/start-tuning/das-tuning-dubai-red-bmw-m4-back-tuning-shop-mob.png",
        alt: "Discover the art of car tuning at Das Tuning, Dubai, as showcased by the striking red BMW M4 at a tuning shop."
    },
      {
        src: "./Images/home/start-tuning/das-tuning-dubai-orange-renault-megane-rs-hood-open-tuning-mob.png",
        alt: "Experience the precision of car tuning services at Das Tuning, Dubai, as our experts tune the powerful orange Renault Megane RS with its hood open."
    }, {
        src: "./Images/home/start-tuning/das-tuning-dubai-white-mercedes-c63s-car-tuning-road-performance-mob.png",
        alt: "Experience the ultimate performance on the road with our customized car tuning services for the white Mercedes C63S at Das Tuning in Dubai. Unleash the power of precision engineering!"
    },
      {
        src: "./Images/home/start-tuning/das-tuning-dubai-matte-grey-mclaren-570s-rear-tuning-shop-mob.png",
        alt: "Enhance your driving experience with our professional car tuning services for the matte grey McLaren 570S at the Das Tuning shop in Dubai."
    },
]
export const clients = [{
title:"What clients say?",
desc:"We are dedicated to delivering exceptional bespoke car tuning services to our clients! But don't just take our word for it, see what our clients have to say about our tuning services in Dubai and the UAE.",
roundLogo:"./Images/logo/das-tuning-logo-car-tuning-services-dubai.png",
logoalt:"The compact and stylish Das Tuning logo representing their exceptional car tuning services in Dubai.",
google:"./Images/home/reviews/das-tuning-google-5-stars-review-car-tuning-services-dubai.svg",
alt:"The Google 5 stars review logo showcasing Das Tuning's outstanding car tuning services in Dubai."
}]
export const client_reviews  =[
    {
        name:"Elton Dias",
        img:"./Images/home/reviews/das-tuning-car-tuning-services-positive-google-review.png",
        alt:"This positive Google review from a satisfied customer mentions the exceptional team and car tuning services at Das Tuning.",
        time:"3 years ago",
        star:[
            {  icon:<BsStarFill/>},
            {  icon:<BsStarFill/>} ,
            {  icon:<BsStarFill/>} ,
            {  icon:<BsStarFill/>} ,
            {  icon:<BsStarFill/>} ,
          ],
        desc:"What a GREAT experience dealing with father and son combo. The best team. The best service. the BEST of everything you really need for your car(s)"
           },
    {
name:"Anis",
img:"./Images/home/reviews/das-tuning-automotive-tuning-services-positive-google-review.png",
alt:"Uncover the efficient and transparent car tuning services at Das Tuning through this positive Google review from a satisfied customer.",
star:[
  {  icon:<BsStarFill/>},
  {  icon:<BsStarFill/>} ,
  {  icon:<BsStarFill/>} ,
  {  icon:<BsStarFill/>} ,
  {  icon:<BsStarFill/>}
],
time:"2 years ago",
desc:"Hidden gem amongst the many 'premium' garages in Dubai. Ilyas is a trustworthy and honest person. The team are fully transparent and aim to be efficient as possible whilst doing great work. Would recommend them to anyone."
   },
   {
    name:"Ribal Talal    ",
    img:"./Images/home/reviews/das-tuning-tuning-services-positive-google-review-quality-speed.png",
    alt:"Discover the high-quality and speedy car tuning services at Das Tuning through this positive Google review from a satisfied customer.",
    star:[
        {  icon:<BsStarFill/>},
        {  icon:<BsStarFill/>} ,
        {  icon:<BsStarFill/>} ,
        {  icon:<BsStarFill/>} ,
        {  icon:<BsStarFill/>}
      ],
    time:"3 years ago",
    desc:"Highly underrated tuning shop in dubai. Virtually anything is possible with them. Been dealing with them for the past year. Very reasonable pricing considering the quality and the speed of the service they provide. Highly recommend, trustworthy."
       },
   
           {
            name:"Harry Politis",
            img:"./Images/home/reviews/das-tuning-positive-google-review-car-tuning-professionalism-prices.png",
            alt:"This positive Google review showcases the professionalism and amazing prices offered by Das Tuning's car tuning services.",
            time:"3 years ago",
            star:[
                {  icon:<BsStarFill/>},
                {  icon:<BsStarFill/>} ,
                {  icon:<BsStarFill/>} ,
                {  icon:<BsStarFill/>} ,
                {  icon:<BsStarFill/>} ,
              ],
            desc:"Amazingly priced considering how professional and experienced these guys are. Been with them exclusively for all maintenance and performance related stuff for my golf for just over 3 years."
               },
               {
                name:"Usman Sheikh ",
                img:"./Images/home/reviews/das-tuning-positive-google-review-tuning-services.png",
                alt:"Discover top-notch car tuning services at Das Tuning through this Google review praising excellent service and professional advice.",
                time:"3 years ago",
                star:[
                    {  icon:<BsStarFill/>},
                    {  icon:<BsStarFill/>} ,
                    {  icon:<BsStarFill/>} ,
                    {  icon:<BsStarFill/>} ,
                    {  icon:<BsStarFill/>} ,
                  ],
                desc:"Excellent service here! Very professional advice given on the issue I was having with my car. highly recommend this place to anyone."
                   },
                   {
                    name:"Fahim Ayoubee",
                    img:"./Images/home/reviews/das-tuning-positive-google-review-efficiency-best-solutions-lowest-cost.png",
                    alt:"This positive Google review highlights Das Tuning's efficiency and ability to provide the best solutions for the lowest cost in their car tuning services.",
                    time:"3 years ago",
                    star:[
                        {  icon:<BsStarFill/>},
                        {  icon:<BsStarFill/>} ,
                        {  icon:<BsStarFill/>} ,
                        {  icon:<BsStarFill/>} ,
                        {  icon:<BsStarFill/>} ,
                      ],
                    desc:"I am Very happy with the services they provided me. They are very professional and efficient with the best solution for the lowest cost. I would definitely recommend them.                    "
                       }
]
export const blogs = [{
    title:"DAS News",
    desc:"Explore our selection of informative news articles related to our car tuning services in Dubai and the UAE. Stay up to date on industry insights and latest trends."
}]