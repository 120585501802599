import React from 'react'
import { BannerContainer  } from '../../components/sections/Container'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
import { about } from '../../data/about'
import AboutIntro from './AboutIntro'
import TuningProcess from './TuningProcess'
import WhyDasTuning from './WhyDasTuning'
import WhatWeDo from './WhatWeDo'
 import Footer from '../../components/footer/Footer'
import GTM from '../../googletagmanager/GTM'
import { FloatingButton } from '../../components/buttons/floating-button/FloatingButton'
import { AboutHelmet } from '../../preclicktags/PreClick'
 
const About = () => {
  return (
    <>
    <GTM/>
    <AboutHelmet/>
    <DefaultNav/>
    <FloatingButton/>
    <BannerContainer>
      {about.map((item)=>(<>
    
<div className='hidden md:block '>
  <div className=' h-auto '>
 <img src={item.banner_desk} alt={item.alt} className='w-full object-cover box-shadow-main-banner lg:rounded-[16px]'/> 
 </div>
 </div>

 <div className='block md:hidden '>
 <img src={item.banner_mob} alt={item.alt} className='w-full s:h-96 pt-8 sm:pt-12  object-cover lg:rounded-[16px]'/> 
 </div>
 </>))}
 </BannerContainer>
 <AboutIntro/>
<TuningProcess/>
<WhyDasTuning/>
<WhatWeDo/>
<Footer/>
        </>
  )
}

export default About
