import React, { useState, useEffect } from "react";
import { cookie_consent } from "../../data/cookie";
import "../../pages/home/animations.css";
  import { BsPalette } from "react-icons/bs";
import { nav_button } from "../../data/components/button";

   const CookieConsent = () => {
    const [accepted, setAccepted] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
  
    useEffect(() => {
      const acceptedCookies = getCookie("acceptedCookies");
      if (acceptedCookies) {
        setAccepted(true);
      } else {
        setTimeout(() => {
          setShowBanner(true);
        }, 3000);
      }
    }, []);
  
    function setCookie(name, value, days) {
      const expires = new Date(Date.now() + days * 864e5).toUTCString();
      document.cookie = `${name}=${encodeURIComponent(
        value
      )}; expires=${expires}`;
    }
  
    function getCookie(name) {
      return document.cookie.split("; ").reduce((r, v) => {
        const parts = v.split("=");
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, "");
    }

  return (
    <>
       {showBanner && !accepted && (
        <div className="flex justify-center ">
               {cookie_consent.map((val) => (
          <div className="w-10/12 md:w-7/12 lg:w-5/12 xl:w-5/12 2xl:w-4/12 bottom-2 3xl:w-3/12 fixed z-50 md:left-4 p-3   flex justify-center md:p-4 lg:p-6 border-2 border-dasRed md:-bottom-2  lg:-bottom-4 bg-dasBlackTrans text-dasRed   flex-col  box-shadow rounded-[16px]  animated-banner ">        
            <div className="  flex flex-row gap-x-4">
                   <i><BsPalette/></i>
                   <h5 className="bold  ">{val.h3}</h5>
                   </div>
              <div className=" pt-2 xl:pt-2  mx-auto  ">
                <p className="text-normalWhite">{val.p1}
                   <a href="/cookie-policy" className="px-1 text-dasRed underline">
                    Cookie Policy.
                  </a>
                </p>
                  <div className=" pt-2 lg:pt-0 flex justify-end">
                  <button
          className="  bg-dasRed border-2 hover:bg-primaryhover active:bg-primaryhover border-solid border-dasRed  text-normalWhite px-2 p-2 sm:p-3 sm:px-5 rounded-lg  my-auto  "
          onClick={() => {
            setAccepted(prevAccepted => !prevAccepted);
            setCookie("acceptedCookies", true, 365);
            setShowBanner(prevShowBanner => !prevShowBanner);
          }} >
          <text className="my-auto button-text"><p>{nav_button[1].buttonText}</p></text>
        </button>
                  </div>
              </div>
          </div>
                 ))}
        </div>
     )}
    </>
  );
};
 

export default CookieConsent;
