import React from 'react'
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { ArticleContainer, SectionContainer } from '../../../components/sections/Container';
import vector from '../../../images/article/Instagram_vector.svg'
import { BsInstagram } from "react-icons/bs";

export const Instagram =() => {
  return(
      <div className='bg-dasBlackTrans flex my-auto justify-center rounded-b-[16px] w-full  h-16 absolute   text-white left-0 right-0 bottom-0'>
      <div className="flex  flex-row gap-x-2">
        <h4 className='my-auto'><BsInstagram/></h4>
        <h4 className='my-auto'><img src={vector} alt="Instagram-logo" /></h4>
      </div>
        </div>
  )
}
export const InstaImageDesktop = ({ images }) => {
  const handleClickInstagram = () => {
      window.open(" https://www.instagram.com/dastuningae/", "_blank");
    };
    if (!images.length) {
      return null;
    }
return (
<>
<SectionContainer>
<ArticleContainer>
<div>
<div className="lg:grid   md:grid-cols-3 md:gap-8 xl:gap-12 2xl:gap-14 ">
    {images.map((imageSrc, index, altforImages) => (
      <div key={index}>
        <div className='  relative' role="button" onClick={handleClickInstagram}>
          <div className="relative">
            <img src={imageSrc} alt={altforImages} className='rounded-[16px] object-cover lg:h-72  h-56 w-full'/>
            <Instagram/>
          </div>
        </div>
      </div>
    ))}
   </div>
  </div>
</ArticleContainer>
</SectionContainer>

</>
)
}

export const InstaImage = ({ images }) => {
    const handleClickInstagram = () => {
        window.open(" https://www.instagram.com/dastuningae/", "_blank");
      };
      if (!images.length) {
        return null;
      }
  return (
<>
<SectionContainer>
<ArticleContainer>
<div>
<Swiper
      spaceBetween={0}
      modules={[Pagination]}
      breakpoints={{
        0: {
          slidesPerView: 1.5,
        },
        480: {
          slidesPerView: 1.8,
        },
        648: {
          slidesPerView: 2.5,
          centeredSlides: false,
        },
        992: {
          slidesPerView: 3,
          centeredSlides: false,
        },
      }}
    >
      {images.map((imageSrc, index, altforImages) => (
        <SwiperSlide key={index}>
          <div className='ms-3 lg:ms-0 lg:px-2 relative' role="button" onClick={handleClickInstagram}>
            <div className="relative">
              <img src={imageSrc} alt={altforImages} className='rounded-[16px] object-cover lg:h-60 h-56 w-12/12'/>
              <Instagram/>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    </div>
</ArticleContainer>
</SectionContainer>

</>
  )
}

 