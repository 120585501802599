import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import axios from 'axios';

export const HomeHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Transform Your Ride | Car Tuning in Dubai | Das Tuning "
    : "Transform Your Ride | Car Tuning in Dubai | Das Tuning ";
  const description = isMobile
    ? "Enhance your car's performance & experience the thrill of driving with our top-quality car tuning services in Dubai"
    : " Experience Best Car Tuning Services in Dubai. Our team of highly skilled & certified professionals will unleash the full potential of your car. Call Today!    ";
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Service",
    url: "https://www.dastuning.com/",
    logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
    image:
      "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png ",
    telephone: "+971 50 752 6677, +971 50 515 6697",
    priceRange: "Free Consultation",
    openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit A ",
      addressLocality: "Al Quoz Industrial Area 3",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },  
    sameAs: [
      "https://www.facebook.com/dastuning1/",
      "https://www.instagram.com/dastuningae/",
      "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
    ],
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const AboutHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "About Us | Expertise You Can Trust | Das Tuning"
    : "About Us | Expertise You Can Trust | Das Tuning";
  const description = isMobile
    ? " Over 17+ years of car tuning expertise. Unmatched performance upgrades. Call us for top-quality car tuning!"
    : "Experience top-notch car tuning, chip tuning, engine tuning services, and more for your Luxury European & German vehicles. Visit Das Tuning in Dubai now!";

    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Service",
      url: "https://www.dastuning.com/",
      logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
      image:
        "https://www.dastuning.com/Images/about/dubai-car-tuning-owner.png",
      telephone: "+971 50 752 6677, +971 50 515 6697",
      priceRange: "Free Consultation",
      openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Unit A ",
        addressLocality: "Al Quoz Industrial Area 3",
        addressRegion: "Dubai",
        addressCountry: "UAE",
      },  
      sameAs: [
        "https://www.facebook.com/dastuning1/",
        "https://www.instagram.com/dastuningae/",
        "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
      ],
    };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const ContactHelmet = () => {
  const title = "Contact Us |Car Tuning Dubai | Das Tuning";
  const description =
    "Call us at 050-515-6697 & get in touch with our expert team for exceptional car tuning services in Dubai.";

    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Service",
      url: "https://www.dastuning.com/",
      logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
      image:
        "https://www.dastuning.com/Images/contact/das-tuning-owner-red-ferrari-tablet.png",
      telephone: "+971 50 752 6677, +971 50 515 6697",
      priceRange: "Free Consultation",
      openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Unit A ",
        addressLocality: "Al Quoz Industrial Area 3",
        addressRegion: "Dubai",
        addressCountry: "UAE",
      },  
      sameAs: [
        "https://www.facebook.com/dastuning1/",
        "https://www.instagram.com/dastuningae/",
        "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
      ],
    };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const ThankyouHelmet = () => {
  const title = "Your Satisfaction is Our Goal | Das Tuning";
  const description =
    "Thank you for tuning it up with us! Your satisfaction is our top priority. Trust our expertise for all your car tuning needs. ";

    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Service",
      url: "https://www.dastuning.com/",
      logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
      image:
    "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
      telephone: "+971 50 752 6677, +971 50 515 6697",
      priceRange: "Free Consultation",
      openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Unit A ",
        addressLocality: "Al Quoz Industrial Area 3",
        addressRegion: "Dubai",
        addressCountry: "UAE",
      },  
      sameAs: [
        "https://www.facebook.com/dastuning1/",
        "https://www.instagram.com/dastuningae/",
        "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
      ],
    };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const BMWHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "BMW Tuning | Certified Technicians | Das Tuning "
    : "BMW Tuning | Car Tuning in Dubai | Das Tuning ";
  const description = isMobile
    ? "Unlock the full potential of your BMW with our professional car tuning services in Dubai. Visit us today!"
    : "Unlock the full potential of your BMW with our professional car tuning services in Dubai. Enhance performance and unleash power. Visit us today! ";
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Service",
    url: "https://www.dastuning.com/bmw",
    logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
    image:
      "https://www.dastuning.com/Images/featured-brands/bmw/bmw-m4-tuning-dubai.png",
    telephone: "+971 50 752 6677, +971 50 515 6697",
    priceRange: "Free Consultation",
    openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit A ",
      addressLocality: "Al Quoz Industrial Area 3",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },  
    sameAs: [
      "https://www.facebook.com/dastuning1/",
      "https://www.instagram.com/dastuningae/",
      "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
    ],
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const AudiHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Audi Tuning in Dubai | Boost Performance | Das Tuning"
    : "Audi Tuning in Dubai | Boost Performance | Das Tuning";
  const description = isMobile
    ? "Boost power, optimize engine performance, and enjoy an exhilarating driving experience with our Audi tuning services."
    : "Elevate your Audi's performance with our Audi tuning solutions. Boost power, optimize engine performance, and enjoy an exhilarating driving experience.";
    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Service",
      url: "https://www.dastuning.com/audi",
      logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
      image:
        "https://www.dastuning.com/Images/featured-brands/audi/dubai-car-tuning-audi-das-tuning.png",
      telephone: "+971 50 752 6677, +971 50 515 6697",
      priceRange: "Free Consultation",
      openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Unit A ",
        addressLocality: "Al Quoz Industrial Area 3",
        addressRegion: "Dubai",
        addressCountry: "UAE",
      },  
      sameAs: [
        "https://www.facebook.com/dastuning1/",
        "https://www.instagram.com/dastuningae/",
        "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
      ],
    };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const VolkswagenHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Expert Volkswagen Tuning in Dubai | Das Tuning"
    : "Expert Volkswagen Tuning in Dubai | Das Tuning";
  const description = isMobile
    ? "Maximize the performance of your Volkswagen with our top-notch tuning solutions. Get the best results today!"
    : "Maximize the performance of your Volkswagen with our top-notch tuning solutions. Experience enhanced power, improved handling, & unmatched driving pleasure.";
    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Service",
      url: "https://www.dastuning.com/volkswagen",
      logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
      image:
        "https://www.dastuning.com/Images/featured-brands/volkswagen/white-volkswagen-gti-clubsport-car-tuning-das-tuning.png ",
      telephone: "+971 50 752 6677, +971 50 515 6697",
      priceRange: "Free Consultation",
      openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Unit A ",
        addressLocality: "Al Quoz Industrial Area 3",
        addressRegion: "Dubai",
        addressCountry: "UAE",
      },  
      sameAs: [
        "https://www.facebook.com/dastuning1/",
        "https://www.instagram.com/dastuningae/",
        "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
      ],
    };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const MercedesHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Expert Mercedes Tuning Services in Dubai | Das Tuning"
    : "Expert Mercedes Tuning Services in Dubai | Das Tuning";
  const description = isMobile
    ? "Experience unrivaled performance & a thrilling driving experience with our Mercedes tuning services. Visit us today! "
    : "Experience unrivaled performance, enhanced dynamics, and a thrilling driving experience with our bespoke Mercedes tuning services in Dubai. Visit us today!";

    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Service",
      url: "https://www.dastuning.com/mercedes",
      logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
      image:
        "https://www.dastuning.com/Images/mercedes/white-mercedes-c63s-car-tuning-das-tuning.png",
      telephone: "+971 50 752 6677, +971 50 515 6697",
      priceRange: "Free Consultation",
      openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Unit A ",
        addressLocality: "Al Quoz Industrial Area 3",
        addressRegion: "Dubai",
        addressCountry: "UAE",
      },  
      sameAs: [
        "https://www.facebook.com/dastuning1/",
        "https://www.instagram.com/dastuningae/",
        "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
      ],
    };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const OtherBrandHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Top Car Tuning Services in Dubai | Das Tuning"
    : "European & German Car Tuning Experts | Das Tuning";
  const description = isMobile
    ? " Experience professional car tuning services at our renowned Dubai tuning shop. To find out more, call us today!    "
    : " Experience the best car tuning services in Dubai and elevate your vehicle's performance to new heights. Get expert tuning for optimal results today!";

    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Service",
      url: "https://www.dastuning.com/car-tuning-brands",
      logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
      image:
        "https://www.dastuning.com/Images/other-brands/das-tuning-dubai-tuning-shop-orange-lamborghini-aventador-silver-rolls-royce-maroon-lancia.png",
      telephone: "+971 50 752 6677, +971 50 515 6697",
      priceRange: "Free Consultation",
      openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Unit A ",
        addressLocality: "Al Quoz Industrial Area 3",
        addressRegion: "Dubai",
        addressCountry: "UAE",
      },  
      sameAs: [
        "https://www.facebook.com/dastuning1/",
        "https://www.instagram.com/dastuningae/",
        "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
      ],
    };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const CookieHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Cookies Policy | Das Tuning"
    : "Cookies Policy | Das Tuning";
  const description = isMobile
    ? "Enhance your browsing experience with our website's cookies. Customize and manage preferences. Read our policy.	    "
    : "We use cookies on our website to improve your browsing experience. Manage cookies on our website & customize your browsing experience.";
    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Service",
      url: "https://www.dastuning.com/cookie-policy",
      logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
     
      telephone: "+971 50 752 6677, +971 50 515 6697",
      priceRange: "Free Consultation",
      openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Unit A ",
        addressLocality: "Al Quoz Industrial Area 3",
        addressRegion: "Dubai",
        addressCountry: "UAE",
      },  
      sameAs: [
        "https://www.facebook.com/dastuning1/",
        "https://www.instagram.com/dastuningae/",
        "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
      ],
    };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const PrivacyHelmet = () => {
  const title = "Privacy Policy | Das Tuning";

  const description =
    "We value the privacy of your personal information. We only request personal data necessary to provide our service and obtain it fairly with your consent.  "
  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
    </Helmet>
  );
};
export const TandCHelmet = () => {
  const title = "Terms and Conditions | Das Tuning ";

  const description =
    " By using our website you agree to abide by these legal Terms. These Terms shall be governed by and construed in accordance with the laws of the UAE.";

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
    </Helmet>
  );
};
export const SitemapHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Site Map | Best Car Tuning Services | Das Tuning"
    : "Site Map | Best Car Tuning Services | Das Tuning";
  const description = isMobile
    ? " Das Tuning Site Map - explore our wide range of bespoke car tuning services in Dubai. Visit Us Today!"
    : "  Das Tuning Site Map - explore our wide range of bespoke car tuning services in Dubai. Visit Us Today! ";
    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Service",
      url: "https://www.dastuning.com/sitemap",
      logo: "https://www.dastuning.com/Images/logo/das_tuning_logo_black.svg",
      image:
        "https://www.dastuning.com/Images/services/seo/Trustline-SEO-Services.svg",
      telephone: "+971 50 752 6677, +971 50 515 6697",
      priceRange: "Free Consultation",
      openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Unit A ",
        addressLocality: "Al Quoz Industrial Area 3",
        addressRegion: "Dubai",
        addressCountry: "UAE",
      },  
      sameAs: [
        "https://www.facebook.com/dastuning1/",
        "https://www.instagram.com/dastuningae/",
        "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
      ],
    };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const NotFoundHelmet = () => {
  const title = "Error 404 . Page Not Found";
  const description =
    "Oops! The page you are looking for cannot be found. Please check the URL or contact us for help: ilyas@dascenter.ae";
  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
    </Helmet>
  );
};
export const BlogHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Automotive Excellence | Das Tuning | Blog "
    : "Automotive Excellence | Das Tuning | Blog";
  const description = isMobile
    ? "Stay updated with our blog featuring BMW, Audi, Mercedes, Volkswagen, and more. Discover valuable tuning tips today!    "
    : "Dive into our blog for the latest on car tuning services in Dubai. Explore BMW, Audi, Mercedes, Volkswagen, and more. Elevate your driving experience today. ";

    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Service",
      url: "https://www.dastuning.com/blogs",
      logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
      image:
        "https://www.dastuning.com/Images/blogs/white-mercedes-c63s-car-tuning-das-tuning.jpg",
      telephone: "+971 50 752 6677, +971 50 515 6697",
      priceRange: "Free Consultation",
      openingHours: "Sun-Thu 08:00-19:00 Sat 09:00-18:00 ",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Unit A ",
        addressLocality: "Al Quoz Industrial Area 3",
        addressRegion: "Dubai",
        addressCountry: "UAE",
      },  
      sameAs: [
        "https://www.facebook.com/dastuning1/",
        "https://www.instagram.com/dastuningae/",
        "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured",
      ],
    };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const  ArticleHelmet = ({ id }) => {
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
        setPost(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchPost();
  }, [id]);

  if (!post) {
   
    return null;
  }

  const title = post.metaTitle;
  const description = post.metaDescription;
  const keywords = post.metaKeywords;
  const url = `https://www.dastuning.com//blogs-article/${id}`;  

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    url,
    headline: title,
    description,
    datePublished: post.createdAt,
    dateModified: post.updatedAt || post.createdAt,
 
    publisher: {
      "@type": "Organization",
      name: "Das Tuning",  
      logo: "https://www.dastuning.com/Images/logo/das-tuning-car-tuning-services-dubai-logo-black.png",
    },
    mainEntityOfPage: url,
    image: `https://www.dastuning.com/${post.mainBanner}`,  
  };

  return (
<Helmet>
  <title>{title}</title>
  <meta name="description" data-react-helmet="true" content={description} />
  <meta name="keywords" content={keywords} />
  <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
</Helmet>

  );
};
