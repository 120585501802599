import React from 'react'
import { ContainerInside, SectionContainer } from '../sections/Container'
import DefaultNav from '../navbar/default-nav/DefaultNav'
 import Footer from '../footer/Footer'
import { cookie } from '../../data/cookie'
import { FloatingButton } from '../buttons/floating-button/FloatingButton'

const Cookie = () => {
  return (
    <div>
      <DefaultNav/>
      <FloatingButton/>
<ContainerInside>
  <SectionContainer>
 
  {cookie.map((val)=>(
<>
<h1>{val.title}</h1>
<p className='lg:pt-4 pt-2'>{val.desc1}</p>
<p className='lg:pt-4 pt-2'>{val.desc2}</p>
<h3 className='pt-4 lg:pt-6'>{val.subtitle1}</h3>
<p className='lg:pt-4 pt-2'>{val.desc3}</p>
<h3 className='pt-4 lg:pt-6'>{val.subtitle2}</h3>
<p className='lg:pt-4 pt-2'>{val.desc4}</p>
</>
))}

  </SectionContainer>
  </ContainerInside>
 <Footer/>
    </div>
  )
}

export default Cookie
