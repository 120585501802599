import { useState } from "react";
 import  {BsWhatsapp, BsXLg, BsTelephone,   } from "react-icons/bs";
 

export const MobFloatingButton = ({logo, alt}) => {
    const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div className="fixed z-10  bottom-2 md:bottom-10 right-2 lg:hidden block">
      <button
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 
        <div className="bg-dasBlack  text-dasRed font-bold p-2 py-4 rounded-full">
          <h5 className="px-5" style={{fontSize:"16px"}}><BsXLg/></h5>
          </div> 
          : 
        <div  className="flex flex-row gap-x-1">
          <div className="bg-dasBlack p-3 h-12 w-12 rounded-full">
            <img src={logo} 
            alt={alt} 
            className="w-full h-full  object-cover"/>
            </div>
            <div   className="bg-dasBlack flex align-middle  text-dasRed font-bold h-12 p-2 rounded-full">
        <div  className="flex flex-row gap-x-1 my-auto px-1">
          <h5 className="my-auto" style={{fontSize:"16px"}}><BsTelephone/></h5>
          <div className="border-l h-4 mx-1">
          </div>
          <h5 className="my-auto" style={{fontSize:"16px"}}> <BsWhatsapp /></h5> 
          </div>
          </div>
          </div>
          }
      </button>
      {isOpen && (
        <div className="absolute bottom-[calc(46px+1rem)] right-0 flex flex-col space-y-2 items-end">
          <a
            href="tel:+971-50-515-6697"
            className="bg-dasBlack  text-dasRed font-bold  p-4 rounded-full"
          >
          <h5 className="px-3" style={{fontSize:"16px"}}><BsTelephone/></h5>
          </a>
          <a
            href="https://wa.me/message/UIUAMHPZQRFAC1"
            className="bg-dasBlack  text-dasRed font-bold  p-4 rounded-full"
          >
           <h5 className="px-3" style={{fontSize:"16px"}}><BsWhatsapp/></h5>
          </a>
        </div>
      )}
    </div>
  );
  }