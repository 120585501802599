import React from 'react'
import {  BrandsContainer, OtherBrandsContainer, SectionContainer } from '../../components/sections/Container'
import { brand_logo, otherBrands } from '../../data/otherBrands'
const Intro = () => {
  return (
    <>
    {otherBrands.map((val)=>(
 <OtherBrandsContainer>
        <BrandsContainer>
 <h1>{val.title}</h1>
 <p className='pt-2 '>{val.desc}</p>
 <div className='pt-3  '> 
 <div className='  flex  mx-auto lg:py-0 flex-wrap'>
        {brand_logo.map((item)=>(
            <div className='flex flex-col lg:w-1/4 w-1/4 py-4'>
                <div className='w-2/6'>
                <img className=' justify-center  ' 
                src={item.img}
                alt={item.alt}/> 
                </div>
            </div>
        ))}
    </div>
 </div>
 </BrandsContainer>
 </OtherBrandsContainer>
    ))}
    </>

  )
}

export default Intro
