import React from 'react';
 
import ReactPlayer from 'react-player';
import { ArticleContainer,  SectionContainerArticle } from '../../../components/sections/Container';

const Youtube =  ({ videoLink }) => {
  if (!videoLink) {
    return null;
  }
  return (
    <>
    <SectionContainerArticle>
<ArticleContainer> 
<div className='w-full h-64 s:h-68 md:h-96 lg:h-30' style={{    borderRadius: '16px', overflow: 'hidden', position: 'relative' }}>
    <ReactPlayer url={videoLink} width='100%' height='100%' controls={true} style={{position: 'absolute'}}/>
</div>
</ArticleContainer>
</SectionContainerArticle>
     </>
  )
}

export default Youtube


 
