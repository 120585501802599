import React from 'react'
import { about, cards } from '../../data/about'
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { SectionContainer } from '../../components/sections/Container';
import { secondary } from '../../data/components/button';
import { SecondaryButton } from '../../components/buttons/secondary-button/SecondaryButton';
const WhatWeDo = () => {
  return (
   <SectionContainer>
       {about.map((val)=>(
 
<div>
<h2 class="relative inline-block pb-2">{val.subtitle3}</h2>
<p>{val.desc5}</p>
<div className='py-5 block md:hidden'>
<Swiper
                      spaceBetween={0}
                      modules={[Pagination]}
                      className=" "
                      breakpoints={{
                        0: {
                          slidesPerView: 1.1,
                        },
                        480: {
                          slidesPerView: 1.6,
                        },
                        768: {
                          slidesPerView: 2.5,
                          centeredSlides: false,
                        },
                       992: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                      }}
                    >
                      {cards.map((item)=>(
                        <>
                        <SwiperSlide className='px-1 md:px-1'>
                        <div className='flex flex-col flex-grow px-1 md:px-5'>
                        <div className="  border  min-h-full border-dasBlack h-33 sm:h-35 text-normalBlack p-5 my-2 rounded-[16px] "> 
                        <div>
                           <img src={item.img} alt={item.alt} className='rounded-[16px]'/></div>
                            <div className="flex flex-col justify-start pt-8">
                            <h5 >{item.title}</h5>
                            <p className='text-dasGrayDark pt-3'>{item.desc}</p>     
                            </div>                  
                          </div>
                          </div> 
                        </SwiperSlide>
                        </>
                      ))}
                      </Swiper> 
                      </div>

<article className='py-5 md:block  hidden'>
{cards.map((item)=>(
<div className='flex flex-row flex-grow   '>
                        <div className="flex flex-row   border h-auto  border-dasBlack  text-normalBlack p-4 my-2 rounded-[16px] "> 
                        <div className='flex flex-row gap-x-6'>
                        <div className='w-3/12 '>
                           <img src={item.img} alt={item.alt} className='rounded-[16px]'/>
                           </div>
                            <div className="w-9/12 flex flex-col ">
                            <h5 >{item.title}</h5>
                            <p className='text-dasGrayDark pt-3'>{item.desc}</p>     
                            </div>      
                            </div>            
                          </div>
                          </div> 
                    ))}          

</article>
<div className='flex justify-center'>
<SecondaryButton  buttonText={secondary[0].buttonText}
                redirect="/contact-us"/> 
                </div>
</div>

))}
    </SectionContainer>
  )
}

export default WhatWeDo
