import React from 'react'
import { useState, useEffect } from "react";
  
export const TertiaryButtonIcon = ({ buttonText, buttonIcon}) => {
    return (
      <div>
        <>
          <button
            className=" bg-transparent w-full  text-dasRed    my-auto  "
          >
            <div className="flex  justify-center flex-row gap-x-3">
            <h4>{buttonIcon}</h4>
            <h6>{buttonText}</h6>
            </div>
          </button>
        </>
      </div>
    );
  };