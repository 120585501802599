import React from "react";
import { useState, useEffect } from "react";
export const PrimaryButton = ({ buttonText, redirect }) => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  function handleClick() {
    setRedirectTo(redirect);
  }

  return (
    <div>
      <>
        <button
          className="  bg-dasRed border-2 hover:bg-primaryhover active:bg-primaryhover border-solid border-dasRed  text-normalWhite px-2 p-2 sm:p-3 sm:px-5 rounded-lg  my-auto  "
          onClick={handleClick}
        >
          <text className="my-auto button-text"><p>{buttonText}</p></text>
        </button>
      </>
    </div>
  );
};

export const PrimaryButtonIcon = ({ buttonText, buttonIcon }) => {
 
    return (
      <div  >
        <>
          <button
            className="w-full flex gap-x-1 sm:gap-x-3 flex-row bg-dasRed border-2 hover:bg-primaryhover active:bg-primaryhover border-solid border-dasRed  text-normalWhite px-2 p-2 sm:p-3 sm:px-5 rounded-lg  my-auto  "
          >
            <h6 className="my-auto button-icon">{buttonIcon}</h6>
            <text className="my-auto button-text"><h6>{buttonText}</h6></text>
          </button>
        </>
      </div>
    );
  };