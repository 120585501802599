import React from 'react'
import {   SectionContainer } from '../../components/sections/Container'
import { tuning_result } from '../../data/home'
import Carousel from './playlist/PlaylistCarousal';

const TuningResult = () => {
  return (

    <SectionContainer  >
     <article className='overflow-x-hidden'>
      <h2 class="relative inline-block">{ tuning_result[0].title}</h2>
      <div class="border-b-2 border-dasBlack  lg:w-1/12 w-2/6 sm:w-2/12"></div>
      <p className='py-3 lg:py-3 '>{tuning_result[0].desc}</p>
      <div className=' pt-16 2xl:pt-10'></div>
    <Carousel/>
    <div className='h-53 s:h-50 sm:h-41 md:h-38 lg:h-46 xl:h-49'></div>
      <div className='pt-8 md:pt-20 lg:pt-16 p-2'>
                      </div> 
                    </article>
                  <article>
                  </article>
                    </SectionContainer>
                    )
                  }
export default TuningResult
