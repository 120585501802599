import React from "react";
import { useState, useEffect } from "react";
export const NavButton = ({ buttonText, redirect }) => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  function handleClick() {
    setRedirectTo(redirect);
  }

  return (
    <div>
      <>
        <button
          className="  bg-dasRed border-2 hover:bg-primaryhover active:bg-primaryhover border-solid border-dasRed  text-normalWhite px-2 p-2 sm:p-3 sm:px-5 rounded-lg  my-auto  "
          onClick={handleClick}
        >
          <text className="my-auto button-text"><p>{buttonText}</p></text>
        </button>
      </>
    </div>
  );
};
export const NavButtonTrans = ({ buttonText, redirect }) => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  function handleClick() {
    setRedirectTo(redirect);
  }

  return (
    <div>
      <>
        <button
          className=" bg-transparent hover:bg-secondaryHover active:bg-secondaryactive  text-dasRed  border-2 border-solid border-dasRed p-2 px-2 sm:p-3 sm:px-5 rounded-lg  my-auto  "
          onClick={handleClick}
        >
          <text className="my-auto button-text"><p>{buttonText}</p></text>
        </button>
      </>
    </div>
  );
};
export const MenuButton = ({ buttonText, redirect }) => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  function handleClick() {
    setRedirectTo(redirect);
  }

  return (
    <div className=" ">
        <button
          className="bg-transparent   text-dasRed  border-2 border-solid border-dasRed  py-3 xs:w-56 s:w-80 rounded-lg  my-auto  "
          onClick={handleClick}
        >
          <text className="my-auto button-text"><p>{buttonText}</p></text>
        </button>
    </div>
  );
};