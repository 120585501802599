import React from 'react'
import {  Email, Location, Mob } from '../../components/buttons/reachus-button/Reachus'
import img from '../../images/contact/das-tuning-owner-red-ferrari-tablet.png'
import { contact } from '../../data/contact'
import { SecondaryButtonIcon } from '../../components/buttons/secondary-button/SecondaryButton'
import { secondary } from '../../data/components/button'

const CTA = () => {
  return (
    <>
    {contact.map((val)=>(
    <div className='p-8 my-2 rounded-[16px] bg-normalBlack  text-normalWhite'>
    <img src={img} alt={val.alt} className='rounded-xl'/>
    <div className="gap-y-4 pt-5 flex flex-col">
    <Mob/> 
    <Email/>
    <Location/>
    </div>
    <article className='mx-auto text-center pt-5'>
<SecondaryButtonIcon    buttonText={secondary[1].buttonText}/> 
</article> 
    </div>
    ))}
    </>
  )
}

export default CTA
