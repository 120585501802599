import React from 'react';
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
import { ContainerInside, SectionContainer } from '../../components/sections/Container'
import { links } from '../../data/links'
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import GTM from '../../googletagmanager/GTM'
import { SitemapHelmet } from '../../preclicktags/PreClick';
import { FloatingButton } from '../../components/buttons/floating-button/FloatingButton';

const Sitemap = () => {
  
  return (
    < div className='bg-dasOffWhite flex flex-col min-h-screen' >
      <GTM/>
      <SitemapHelmet/>
          <DefaultNav/>
          <FloatingButton/>
          <div className='flex-grow'>
<ContainerInside>
  <SectionContainer>
  <div className="grid grid-cols-3 gap-4 " >
    {links.map((val)=>(
    <Link to={val.onclick}>
    <p>{val.text}</p>
    </Link>
    ))}
</div>
  </SectionContainer>
  </ContainerInside>
  </div>
   <Footer/>
  
    </div>
  )
}

export default Sitemap
