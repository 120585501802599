import React from 'react'
import { BannerBrandsContainer,  BrandsContainer } from '../../../components/sections/Container'
 
const Banner = ({image1, alt1}) => {
  return (
  <>
        <BannerBrandsContainer>
           <BrandsContainer>
    
        <div className='mt-4 md:mt-4 lg:pt-12'>
        <img src={image1} alt={alt1} className='w-full box-shadow-main-banner h-96 md:h-96 lg:rounded-[16px] lg:h-full object-cover'/>
        </div>
   
           </BrandsContainer>
           </BannerBrandsContainer>
  </>
  )
}

export default Banner
