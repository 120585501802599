import React  from 'react'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
 import Intro from './Intro'
import OurWork from './OurWork'
 import {BrandsFloatingDesktop} from '../../components/buttons/floating-button/BrandsFloating'
import { brandsFloating } from '../../data/components/button'
import Banner from './Banner'
import { FloatingButtonMob } from '../../components/buttons/floating-button/FloatingButton'
import Footer from '../../components/footer/Footer'
import GTM from '../../googletagmanager/GTM'
import OurServices from './OurServices'
import { OtherBrandHelmet } from '../../preclicktags/PreClick'
const OtherBrands = () => {
  return (
    <>
      <GTM/>
      <OtherBrandHelmet/>
<DefaultNav/>
 <Banner/>
 <BrandsFloatingDesktop 
 logo={brandsFloating[4].logo}
 alt={brandsFloating[4].alt}
brandTitle={brandsFloating[4].brandTitle}
brandDesc={brandsFloating[4].brandDesc}
 />
<FloatingButtonMob/>
<Intro/>
<OurWork/>
<OurServices/>
<Footer/>
</>
  )
}

export default OtherBrands
