import React from 'react'
import { ContainerInside, SectionContainer } from '../../components/sections/Container'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
import { privacy } from '../../data/termsofue'
import Footer from '../../components/footer/Footer'
import GTM from '../../googletagmanager/GTM'
import { PrivacyHelmet } from '../../preclicktags/PreClick'
import { FloatingButton } from '../../components/buttons/floating-button/FloatingButton'

const Privacy = () => {
  return (
    <div>
      <GTM/>
      <PrivacyHelmet/>
      <DefaultNav/>
      <FloatingButton/>
<ContainerInside>
  <SectionContainer>

  {privacy.map((val)=>(
<>
<h1>{val.title}</h1>
<p className='lg:pt-4 pt-2'>{val.desc}</p>
<h3 className='pt-4 lg:pt-6'>{val.subtitle1}</h3>
<p className='lg:pt-4 pt-2'>{val.desc2}</p>
<h3 className='pt-4 lg:pt-6'>{val.subtitle2}</h3>
<p className='lg:pt-4 pt-2'>{val.desc3}</p>
<h3 className='pt-4 lg:pt-6'>{val.subtitle3}</h3>
<p className='lg:pt-4 pt-2'>{val.desc4}</p>
<h5 className='pt-4 lg:pt-6'>{val.sub}</h5>
<p className='lg:pt-4 pt-2'>{val.desc5}</p>
</>
))}

  </SectionContainer>
  </ContainerInside>
 <Footer/>
    </div>
  )
}

export default Privacy
