import React from 'react'

const Graph = ({data}) => {
  return (
<>
<div
 className="flex justify-between text-decoration-none lg:w-8/12 ">
    <h4 className='mt-3 lg:mt-6'> Graph</h4>
    <div className="mt-5 lg:mt-7 gap-x-2 md:gap-x-4 pe-1 flex flex-row">
    <div className="hidden xl:block ">
        <div className='flex flex-row gap-x-3'>
      <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className="w-3 h-3 my-auto bg-paleRed rounded-full"></div>
        <small className='my-auto'>Stock BHP</small>
    </div>
      
      <div className="flex flex-row md:gap-x-2">
    <div className=" w-3 h-3 my-auto bg-red-600 rounded-full"></div>
        <small className='my-auto'>Tuned BHP</small>
    </div>
    
      <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className=" w-3 h-3 my-auto bg-paleGreen rounded-full"></div>
        <small className='my-auto'>Stock Nm</small>
    </div>
     
    <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className=" w-3 h-3 my-auto bg-green-600 rounded-full"></div>
        <small className='my-auto'>Tuned Nm</small>
    </div>
    </div>
    </div>
    <div className="xl:hidden block ">
        <div className='flex flex-row gap-x-3'>

      <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className=" w-2 h-2 my-auto bg-red-600 rounded-full"></div>
        <small className='my-auto'>Tuned BHP</small>
    </div>
       
    <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className=" w-2 h-2 my-auto bg-green-600 rounded-full"></div>
        <small className='my-auto'>Tuned Nm</small>
    </div>
    </div>
    </div>
    </div>
    </div>

    <div className=' lg:w-8/12 py-2 flex   h-51   md:h-54 lg:h-60 min-h-min box-shadow object-cover pt-2 mt-3 bg-normalWhite rounded-[16px]'>
  {data.map((val)=>(
    <img 
      src={val.img}
      alt={val.alt} 
      className='w-full obejct-cover flex-grow rounded-[16px]' 
    />
  ))}
</div>





</>
  )
}

export default Graph

export const GraphHome = ({data}) => {
  return (
<>
<div
 className="flex justify-between text-decoration-none lg:w-full ">
    <h4 className='mt-3 lg:mt-6'> Graph</h4>
    <div className="mt-5 lg:mt-6 gap-x-2 md:gap-x-4 pe-1 flex flex-row">
      <div className="hidden s:block ">
        <div className='flex flex-row gap-x-3'>
      <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className="w-3 h-3 my-auto bg-paleRed rounded-full"></div>
        <small className='my-auto'>Stock BHP</small>
    </div>
       
      <div className="flex flex-row md:gap-x-2">
    <div className=" w-3 h-3 my-auto bg-red-600 rounded-full"></div>
        <small className='my-auto'>Tuned BHP</small>
    </div>
    
      <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className=" w-3 h-3 my-auto bg-paleGreen rounded-full"></div>
        <small className='my-auto'>Stock Nm</small>
    </div>
     
    <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className=" w-3 h-3 my-auto bg-green-600 rounded-full"></div>
        <small className='my-auto'>Tuned Nm</small>
    </div>
    </div>
    </div>
    </div>
    </div>

    <div className=' lg:w-full py-2 flex  h-52 md:h-52 lg:h-auto min-h-min box-shadow object-cover pt-2 mt-3 bg-normalWhite rounded-[16px]'>
  {data.map((val)=>(
    <img 
      src={val.img}
      alt={val.alt} 
      className='w-full obejct-cover flex-grow rounded-[16px]' 
    />
  ))}
</div>
<div className="s:hidden block"   >
  <div className="flex gap-x-3.5 s:gap-x-3 mt-3">
      <div className="flex flex-row gap-x-1   md:gap-x-2">
    <div className="w-2 h-2 my-auto bg-paleRed rounded-full"></div>
        <small className='my-auto'>Stock BHP</small>
    </div>
       
      <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className=" w-2 h-2 my-auto bg-red-600 rounded-full"></div>
        <small className='my-auto'>Tuned BHP</small>
    </div>
    </div>
    <div className='flex flex-row gap-x-4 s:gap-x-3 mt-1'>
      <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className=" w-2 h-2 my-auto bg-paleGreen rounded-full"></div>
        <small className='my-auto'>Stock Nm</small>
    </div>
     
    <div className="flex flex-row gap-x-1 md:gap-x-2">
    <div className=" w-2 h-2 my-auto bg-green-600 rounded-full"></div>
        <small className='my-auto'>Tuned Nm</small>
    </div>
    </div>
    </div>
</>
  )
}