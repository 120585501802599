import React from 'react'
import GridSection from './components/GridSection'
import { grid_section, previous_results, subSection, intro } from '../../data/featuredBrands'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
 import SubSection from './components/SubSection';
import {  PreviousResultsData} from './components/PreviousResults';
import { VolkswagenPower, VolkswagenTorque, graphForVolkswagen } from '../../data/components/tuning';
import Footer from '../../components/footer/Footer';
import { brandsFloating } from '../../data/components/button';
import { BrandsFloatingDesktop } from '../../components/buttons/floating-button/BrandsFloating';
import IntroMob from './components/IntroMob';
import { MobFloatingButton } from '../../components/buttons/floating-button/FeaturedFloatingButtons';
import IntroDesk from './components/IntroDesk';
import GTM from '../../googletagmanager/GTM'
import {  VolkswagenHelmet } from '../../preclicktags/PreClick';

 const Volkswagen = () => {
   return (
<>
<GTM/>
<VolkswagenHelmet/>
<DefaultNav/>
<BrandsFloatingDesktop 
 logo={brandsFloating[3].logo} 
 alt={brandsFloating[3].alt}
brandTitle={brandsFloating[3].brandTitle}
brandDesc={brandsFloating[3].brandDesc}
 />
<MobFloatingButton
logo={brandsFloating[3].logowhite}
alt={brandsFloating[3].alt}
/>
<IntroDesk
image1={intro.volkswagen.image1}
alt1={intro.volkswagen.alt1}
title1={intro.volkswagen.title1}
description1={intro.volkswagen.description1}
title2={intro.volkswagen.title2}
image2={intro.volkswagen.image2}
alt2={intro.volkswagen.alt2}
description2={intro.volkswagen.description2}
/>
<IntroMob
image1={intro.volkswagen.image1}
alt1={intro.volkswagen.alt1}
title={intro.volkswagen.title1}
desc={intro.volkswagen.description1}
title2={intro.volkswagen.title2}
image2={intro.volkswagen.image2}
alt2={intro.volkswagen.alt2}
desc2={intro.volkswagen.description2}
/>
<GridSection 
  title1={grid_section.volkswagen.title1}
  description1={grid_section.volkswagen.description1}
  image1={grid_section.volkswagen.image1}
  alt1={grid_section.volkswagen.alt1}
  title2={grid_section.volkswagen.title2}
  description2={grid_section.volkswagen.description2}
  image2={grid_section.volkswagen.image2}
  alt2={grid_section.volkswagen.alt2}
/>
          <SubSection
            title={subSection.volkswagen.title}
            description={subSection.volkswagen.description}
            image={subSection.volkswagen.image}
            alt={subSection.volkswagen.alt}
          />
<PreviousResultsData
title={previous_results.volkswagen.title}
description={previous_results.volkswagen.description}
image={previous_results.volkswagen.img}
alt={previous_results.volkswagen.alt}
powerData={VolkswagenPower}
torqueData={VolkswagenTorque}
graphData={graphForVolkswagen}/>
<Footer/>
</>
   )
 }
 
 export default Volkswagen
 