import React from 'react'
import { about, icons } from '../../data/about'
import { SectionContainer } from '../../components/sections/Container'

const WhyDasTuning = () => {
  return (
 <SectionContainer>
   {about.map((val)=>(
<div className='bg-dasBlack border-red-500   text-normalWhite md:p-8 p-5 my-2 rounded-[16px]'>
<div className=" flex xl:flex-row lg:flex-row flex-col    justify-between">
<div className='w-full lg:w-6/12'>
    <h2>{val.subtitle2} </h2>
    <p className='pt-2'>{val.desc4}</p>
</div>
<div className='w-full lg:w-6/12 flex py-4 lg:py-0 lg:flex-wrap   flex-wrap'>
        {icons.map((val)=>(
            <div className='flex flex-col   lg:w-1/2 w-1/2 py-4'>
                <i className='flex justify-center mb-3'>{val.icon}</i>
                <p className='mx-auto text-center'>{val.text}  </p>
            </div>
        ))}
    </div>
</div>
</div>
))}
 </SectionContainer>
  )
}

export default WhyDasTuning
