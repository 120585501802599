import { BsArrowRightShort } from "react-icons/bs";

export const contact = [{
title:" Contact us",
desc:"If you have any question or would like to know more about our car tuning services, please fill out the following contact form and we will contact you right away!",
sub1:"Contact directly",
sub2:"Submit request",
alt:" Experience the passion of car tuning with the owner of Das Tuning, immersed in a red Ferrari, exploring car tuning services on his tablet.",
icon:<BsArrowRightShort/>
}]

 