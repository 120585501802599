



import React, { useState, useEffect } from 'react';
import { SectionContainer } from '../../components/sections/Container'
 import { truncateText, useScreenSize, getTitleCharLimit, getParaCharLimit } from '../../utils/truncateText';
import { Link } from 'react-router-dom';
import { DateCreated } from '../article/components/DateShare';
import slugify from 'slugify';

const RecentBlogs = () => {
  const [posts, setPosts] = useState([]);
  const { isLargeScreen, isMediumScreen, isSmallScreen } = useScreenSize();
  const titleCharLimit = getTitleCharLimit(isLargeScreen, isMediumScreen, isSmallScreen);
  const paraCharLimit = getParaCharLimit(isLargeScreen, isMediumScreen, isSmallScreen);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/posts`)
      .then(response => response.json())
      .then(data => {
        const sortedPosts = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPosts(sortedPosts);
      })
      .catch(err => console.error(err));
  }, []);
  return (
 <>
  <SectionContainer>
  <h1 className='mb-5'>Articles</h1>
        <div className="sm:grid sm:grid-cols-2 md:grid-cols-3 md:gap-8 sm:gap-4">
          {posts.map((post) => (
            <div className='my-5 sm:mb-3 sm:mt-0' key={post._id}>
    <Link to={`/blogs/article/${slugify(post.title, { lower: true })}/${post._id}`} style={{ textDecoration:"none" }}>  
              <div className='bg-normalWhite box-shadow rounded-[16px] sm:h-85 lg:h-96 xl:h-auto'>
                <div className='w-auto h-auto'>
                <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className='rounded-t-[16px] object-contain h-auto' />
                </div>
                <div className='p-3'>
                  <h5>{truncateText(post.title, titleCharLimit)}</h5>
                  <p className=' text-dasGrayDark  py-2'>{truncateText(post.mainParagraph, paraCharLimit)}</p> 
                  <div className='font-bold'><p className='text-dasGrayLight '><DateCreated createdAt={post.createdAt}/></p></div>
                </div>
              </div>
              </Link>
            </div>
          ))}
        </div>
      </SectionContainer>
 </>
  )
}

export default RecentBlogs
