 
export const intro={
    "bmw": {
        title1: "BMW ",
        description1: "At Das Tuning, our team of BMW Tuning Specialists in Dubai, UAE, who provide top-notch tuning services and performance upgrades for all BMW enthusiasts. As a leading car tuning garage in Dubai, UAE, our team of certified specialists has extensive experience working with various BMW models. We unlock the full potential of your BMW with our bespoke car tuning solutions, customized to your specific requirements. ",
        image1: " ./Images/featured-brands/bmw/bmw-m4-tuning-dubai.png",
        alt1:"Red and Black BMW M4 with Das Tuning's exceptional car tuning services in Dubai. Enhance performance, precision, and style with our expert tuning solutions.",
        title2:"Engine Tuning ",
        description2:"Improve power, torque, throttle response, and overall drivability with our engine tuning services in Dubai and the UAE. Our certified specialists will upgrade your BMW's performance with mechanical modifications. Speak to our car tuning experts today and find out more about our engine tuning services. ",
        image2:"./Images/featured-brands/bmw/bmw-engine-tuning-das-tuning-dubai.png",
        alt2:"Experience unparalleled power and performance with Das Tuning's top-notch engine tuning services for your BMW in Dubai. Unlock the true potential of your car with our expert tuning solutions."
      },
      "mercedes": {
        title1: "Mercedes Benz",
        description1: "At Das Tuning, our certified team takes a personalized approach to providing the best tuning results to all clients in Dubai and the UAE. Our team will optimize your Mercedes performance to the maximum level using advanced techniques and tools.  ",
        image1: " ./Images/featured-brands/mercedes/white-mercedes-c63s-car-tuning-das-tuning.png",
        alt1:"Experience the pinnacle of luxury and performance with our car tuning services for white Mercedes C63S vehicles at Das Tuning. Elevate your driving experience with our expert modifications and enhancements.",
        title2:"Engine Tuning ",
        description2:"Enhance your Mercedes performance by optimizing engine parameters with the help of our engine tuning services in Dubai and the UAE. Our team of highly skilled and certified professionals provide you with exceptional mechanical modifications, ensuring your Mercedes delivers exhilarating performance. ",
        image2:"./Images/featured-brands/mercedes/das-tuning-mercedes-c63s-engine-tuning.png",
        alt2:"Unlock the power within: Explore Das Tuning's engine tuning services as the hood of a Mercedes C63S opens, revealing the heart of performance."
      },
      "audi": {
        title1: "Audi ",
        description1: "We have a highly skilled team of Audi tuning specialists in Dubai, UAE, providing exceptional tuning services with advanced tuning techniques. As a leading car tuning garage in Dubai, UAE, our team of certified specialists has extensive experience working with various Audi models. Upgrade your Audi with our bespoke car tuning solutions, tailored specifically to meet your requirements.  ",
        image1: "./Images/featured-brands/audi/dubai-car-tuning-audi-das-tuning.png",
        alt1:"Enhance your Audi RS3 performance and style with professional car tuning services in Dubai by Das Tuning. Experience the ultimate automotive customization and power upgrades.",
        title2:"Engine Tuning ",
        description2:"Transform your Audi's engine with our top-notch engine tuning services in Dubai and across the UAE. Our team of highly skilled and certified professionals provide you with exceptional mechanical modifications, ensuring your Audi delivers exhilarating performance. Trust us for premium services, from custom exhausts to turbocharger upgrades and dyno tuning. Elevate your driving experience with Das Tuning. Contact us today! ",
        image2:"./Images/featured-brands/audi/audi-engine-tuning-dubai.png",
        alt2:"Enhance your Audi's performance with professional engine tuning services by Das Tuning, a leading car tuning company in Dubai. Unlock power, efficiency, and exhilaration."
      },
      "volkswagen": {
        title1: "Volkswagen ",
        description1: "At Das Tuning, our team of skilled technicians and tuners specialize in Volkswagen tuning. We provide top-notch car tuning services for all our clients in Dubai and the UAE. We offer a wide range of tuning options tailored specifically for Volkswagen models. Whether you own a Golf GTI, Polo GTI, or any other Volkswagen model, we have the expertise to enhance its performance, aesthetics, and overall driving experience. ",
        image1: "./Images/featured-brands/volkswagen/white-volkswagen-gti-clubsport-car-tuning-das-tuning.png",
        alt1:"Experience the ultimate car tuning services for your white Volkswagen GTI Clubsport at Das Tuning. Enhance performance and style with our expert modifications and upgrades.",
        title2:"Engine Tuning ",
        description2:"Enhance the power and performance of your Volkswagen's engine with the help of our engine tuning services in Dubai and the UAE. Our team of highly skilled and certified professionals provide you with exceptional mechanical modifications, ensuring your Volkswagen delivers exhilarating performance. ",
        image2:"./Images/featured-brands/volkswagen/das-tuning-dubai-volkswagen-gti-clubsport-engine-tuning.png",
        alt2:"   Experience the exceptional engine tuning services for Volkswagen GTI Clubsport at Das Tuning, Dubai's leading car tuning company. Explore the expertise under the open hood."
      },
}

export const grid_section={
    "bmw": {
        title1: "ECU Remapping",
        description1: "Experience the true power and performance of your BMW with our ECU remapping services. At Das Tuning, our team of specialists unleash the hidden potential of your BMW's engine through advanced ECU remapping techniques.",
        image1: "./Images/featured-brands/bmw/bmw-ecu-remapping-das-tuning-dubai.png ",
        alt1:"Enhance your BMW's performance with expert ECU remapping services by Das Tuning in Dubai. Unleash the full potential of your car's engine for an exhilarating driving experience.",
        title2:"Exhaust Upgrades ",
        description2:"Improve the sound, performance, and aesthetics of your BMW with our professional services in Dubai and the UAE.",
        image2:"./Images/featured-brands/bmw/bmw-exhaust-upgrades-dubai.png",
        alt2:"Enhance the roar of your BMW with Das Tuning's premium exhaust upgrades. Our services in Dubai offer unmatched performance and sound customization for your car. Experience the ultimate driving experience with our expert car tuning solutions."
      },
      "mercedes": {
        title1: "Dyno Testing ",
        description1:"Our highly skilled team uses dyno testing to measure and analyze the performance of your Volkswagen. Dyno testing allows us to fine-tune and validate the modifications, ensuring optimal power delivery and overall drivability.",
        image1: "./Images/featured-brands/mercedes/das-tuning-dyno-testing-mercedes-c63s.png ",
        alt1:"Unleash the true potential: Experience Das Tuning's precise dyno testing services on a Mercedes C63S for optimized performance.",
        title2:"Custom ECU Tuning ",
        description2:"At Das Tuning, we help you unlock the full potential of your Mercedes with our custom ECU tuning services in Dubai and the UAE. Our team optimizes the engine's electronic control unit (ECU) to maximize power. Speak to our specialists today and learn more.",
        image2:"./Images/featured-brands/mercedes/das-tuning-mercedes-c63s-ecu-tuning.png",
        alt2:"Enhance performance and control: Das Tuning specializes in custom ECU tuning for Mercedes C63S, optimizing your car's potential."
      },
      "audi": {
        title1: "ECU Remapping ",
        description1:" Experience the true power and performance of your Audi with our ECU remapping services. At Das Tuning, our team of specialists unleash the hidden potential of your Audi's engine through advanced ECU remapping techniques.",
        image1: "./Images/featured-brands/audi/audi-ecu-remapping-das-tuning-dubai.png",
        alt1:"Experience optimized performance with ECU remapping services for your Audi by Das Tuning, a premier car tuning company in Dubai. Unleash power and efficiency.",
        title2:"Turbocharger ",
        description2:"Experience enhanced power and performance with our turbocharger enhancements for your Audi. Our expert team specializes in turbocharger upgrades, allowing you to unleash higher boost levels and enjoy exhilarating acceleration on the road. Get in touch with our team today and discover the incredible potential of turbocharger enhancements for your Audi.",
        image2:"./Images/featured-brands/audi/audi-turbocharger-enhancements-dubai.png",
        alt2:"Experience the power of turbocharger enhancements for your Audi with Das Tuning, a leading car tuning company in Dubai. Boost performance and unleash the full potential of your vehicle."
      },
      "volkswagen": {
        title1: "Dyno Testing ",
        description1:"Our highly skilled team uses dyno testing to measure and analyze the performance of your Volkswagen. Dyno testing allows us to fine-tune and validate the modifications, ensuring optimal power delivery and overall drivability.",
        image1: "./Images/featured-brands/volkswagen/das-tuning-dubai-volkswagen-gti-clubsport-dyno-testing.png",
        alt1:"Unlock the potential of your Volkswagen GTI Clubsport with our precise dyno testing services at Das Tuning, the premier car tuning company in Dubai.",
        title2:"Exhaust Upgrades ",
        description2:"Improve the sound, performance, and aesthetics of your Volkswagen with our professional tuning services in Dubai and the UAE.",
        image2:"./Images/featured-brands/volkswagen/das-tuning-dubai-volkswagen-gti-clubsport-exhaust-upgrade.png",
        alt2:"Enhance your Volkswagen GTI Clubsport's performance and sound with our premium exhaust upgrade services at Das Tuning, Dubai's leading car tuning company."
      },
}
 
export const subSection={
    "bmw": {
        title: "Experience the Difference with Brake Upgrades ",
        description: "Enhance your BMW's safety and enjoy superior stopping power on the road. At Das Tuning, we understand the importance of expertly upgrading your vehicle's braking system. That's why our team of certified professionals provides you with the best brake upgrade solutions.  ",
        image: "./Images/featured-brands/bmw/bmw-brake-upgrades-das-tuning-dubai.png ",
        alt:"Enhance your BMW's braking power with Das Tuning's exceptional brake upgrade services in Dubai. Elevate your driving experience with improved safety and performance. Trust our experts for top-quality brake enhancements. ",
    },
    "mercedes": {
        title: " Exhaust Upgrades",
        description: "Improve the sound, performance, and aesthetics of your Mercedes with our professional tuning services in Dubai and the UAE.  ",
        image: "./Images/featured-brands/mercedes/das-tuning-dubai-mercedes-c63s-exhaust-upgrades.png ",
        alt:"Enhance your Mercedes C63S’s performance and sound with our exhaust upgrade services at Das Tuning, a leading car tuning company in Dubai. ",
    },
    "audi": {
        title: "Brake Upgrades ",
        description: "Upgrading the brakes can enhance the stopping power and overall safety of your Audi. At Das Tuning, we understand the importance of expertly upgrading your car's braking system. which is why we have a team of highly skilled and certified professionals providing you with exceptional brake upgrade solutions in Dubai and the UAE.  ",
        image: "./Images/featured-brands/audi/audi-brake-upgrades-das-tuning-dubai.png",
        alt:"Upgrade your Audi's braking performance with Das Tuning, the premier car tuning company in Dubai. Discover high-quality brake upgrades that enhance safety and precision. Experience exceptional stopping power and control. ",
    },
    "volkswagen": {
        title: "Custom ECU Tuning:  ",
        description: "At Das Tuning, our highly skilled team will tune your Volkswagen's engine parameters to your specific driving preferences and performance goals.  ",
        image: "./Images/featured-brands/volkswagen/das-tuning-dubai-volkswagen-gti-clubsport-ecu-tuning.png ",
        alt:"Experience enhanced performance and power with our custom ECU tuning services for Volkswagen GTI Clubsport at Das Tuning, Dubai's premier car tuning company. ",
    },
}

export const previous_results={
    "bmw": {
        title: "Results of BMW M2",
        description:"At Das Tuning, we take pride in our highly skilled team, which delivers exceptional results. Have a look at our successful tuning projects and the incredible transformations we've achieved for BMW vehicles.",
        img:"./Images/featured-brands/bmw/das-tuning-bmw-m2-car-tuning-dubai.png",
        alt:"A successful BMW M2 car tuning by Das Tuning, delivering enhanced performance and power in Dubai."
      },
    "mercedes": {
        title: "Results of Mercedes C63",
        description:"At Das Tuning, we take pride in our highly skilled team, which delivers exceptional results. Have a look at our successful tuning projects and the incredible transformations we've achieved for Mercedes vehicles.",
        img:"./Images/featured-brands/mercedes/das-tuning-dubai-mercedes-c63s-tuning-results.png",
        alt:"Witness the remarkable results of our Mercedes C63S tuning project at Das Tuning, a leading car tuning company in Dubai."
      },
    "audi": {
        title: "Results of Audi RS3",
        description:"At Das Tuning, we take pride in our highly skilled team, which delivers exceptional results. Have a look at our successful tuning projects and the incredible transformations we've achieved for Audi vehicles.",
        img:"./Images/featured-brands/audi/audi-rs3-results-car-tuning-dubai.png",
        alt:"Witness remarkable results of Audi RS3 with our top-notch car tuning services at Das Tuning, a leading Dubai-based car tuning company. Unleash the true potential of your Audi with our expert tuning solutions."
      },
    "volkswagen": {
        title: "Results of Volkswagen GTI Clubsport",
        description:"At Das Tuning, we take pride in our highly skilled team, which delivers exceptional results. Have a look at our successful tuning projects and the incredible transformations we've achieved for Volkswagen vehicles.",
        img:"./Images/featured-brands/volkswagen/das-tuning-dubai-volkswagen-gti-clubsport-tuning-results.png",
        alt:"Witness the impressive results of our Volkswagen GTI Clubsport tuning project at Das Tuning, Dubai's leading car tuning company."
      }
}

