export const footer = [
    {
    title:'Quick links',
    items:[
        {
        link:"Contact us",
        to:"/contact-us"
    },
    {
        link:"About us",
        to:"/about-us"
    },
    {
        link:"Blog ",
        to:"/blogs"
    },
]
},
{
    title:'Legal  ',
    items:[
        {
        link:"Terms & conditions  ",
        to:"/terms-and-conditions"
    },
    {
        link:"Privacy policy",
        to:"/privacy-policy"
    },
   
]
},
{
    title:'Hours info  ',
    items:[
        {
        link:"Sun-Thurs : 8 AM to 7 PM  ",
  
    },
    {
        link:"Sat : 9 AM to 6 PM",
   
    },
    {
        link:"Friday : Closed",
   
    },
]
},
]
export const  copy_right = [
  {
     item1:"© 2023 All right reserved",
 
    item2:"Powered by Trustline Digital"
  }
]