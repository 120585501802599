import React from 'react'
import { start_tuning, start_tuning_images, start_tuning_images_mob } from '../../data/home'
import { useState, useEffect } from "react";
import { SectionContainer } from '../../components/sections/Container';
import { useMediaQuery } from 'react-responsive'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation, Autoplay } from "swiper";
import { SecondaryButton } from '../../components/buttons/secondary-button/SecondaryButton';
import { secondary } from '../../data/components/button';

const StartTuning = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === start_tuning_images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 640 });

  const images = isMobile ? start_tuning_images_mob : start_tuning_images;
  return ( 

    <SectionContainer  >
    <article className='overflow-x-hidden      '>
    <h2 class="relative inline-block">{ start_tuning [0].title}</h2>
    <div class="border-b-2 border-dasBlack  lg:w-1/12 w-2/6 sm:w-2/12"></div>
    <p className='py-3 lg:py-3'>{start_tuning[0].desc}</p>
</article>
<article className='pt-3 lg:pt-5'>
<Swiper 
   navigation={false}
        modules={[Navigation, Autoplay]} 
        className="mySwiper  rounded-[16px]"
        autoplay={{ delay: 2000 }}
      > 
        {images.map((image, index) => (
    <SwiperSlide className='rounded-[16px]'>
      <img
        key={image.src}
        src={image.src}
        alt={image.alt}
        className={`h-full rounded-[16px] `} 
      />
    </SwiperSlide>
  ))}
      </Swiper> 
</article>

<article className='mx-auto text-center pt-5'>
<SecondaryButton    buttonText={secondary[0].buttonText}
                redirect="/contact-us"/> 
</article>
 
</SectionContainer>
  )
}

export default StartTuning
