 import React from 'react'
import GridSection from './components/GridSection'
import { grid_section, previous_results, subSection, intro } from '../../data/featuredBrands'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
 import SubSection from './components/SubSection';
import {  PreviousResultsData} from './components/PreviousResults';
import { BmwPower, BmwTorque, graphForBMW } from '../../data/components/tuning';
import Footer from '../../components/footer/Footer';
import { brandsFloating } from '../../data/components/button';
import { BrandsFloatingDesktop } from '../../components/buttons/floating-button/BrandsFloating';
import IntroMob from './components/IntroMob';
import { MobFloatingButton } from '../../components/buttons/floating-button/FeaturedFloatingButtons';
import IntroDesk from './components/IntroDesk';
import GTM from '../../googletagmanager/GTM'
import { BMWHelmet } from '../../preclicktags/PreClick';

 const Bmw = () => {
   return (
<>
 <GTM/>
 <BMWHelmet/>
<DefaultNav/>
<BrandsFloatingDesktop 
 logo={brandsFloating[1].logo} 
 alt={brandsFloating[1].alt}
brandTitle={brandsFloating[1].brandTitle}
brandDesc={brandsFloating[1].brandDesc}
 />
<MobFloatingButton
logo={brandsFloating[1].logowhite}
alt={brandsFloating[1].alt}
/>
 
<IntroDesk
title1={intro.bmw.title1}
image1={intro.bmw.image1}
alt1={intro.bmw.alt1}
description1={intro.bmw.description1}
title2={intro.bmw.title2}
 image2={intro.bmw.image2}
 alt2={intro.bmw.alt2}
 description2={intro.bmw.description2}
/>  

<IntroMob
image1={intro.bmw.image1}
alt1={intro.bmw.alt1}
title={intro.bmw.title1}
desc={intro.bmw.description1}
title2={intro.bmw.title2}
image2={intro.bmw.image2}
alt2={intro.bmw.alt2}
desc2={intro.bmw.description2}
/>
<GridSection 
  title1={grid_section.bmw.title1}
  description1={grid_section.bmw.description1}
  image1={grid_section.bmw.image1}
  alt1={grid_section.bmw.alt1}
  title2={grid_section.bmw.title2}
  description2={grid_section.bmw.description2}
  image2={grid_section.bmw.image2}
  alt2={grid_section.bmw.alt2}
/>
          <SubSection
            title={subSection.bmw.title}
            description={subSection.bmw.description}
            image={subSection.bmw.image}
            alt={subSection.bmw.alt}
          />
<PreviousResultsData
title={previous_results.bmw.title}
description={previous_results.bmw.description}
image={previous_results.bmw.img}
alt={previous_results.bmw.alt}
powerData={BmwPower}
torqueData={BmwTorque}
graphData={graphForBMW}/>
<Footer/>
</>
   )
 }
 
 export default Bmw
 