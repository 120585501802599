 
import './App.css';
import Pages from './pages/Pages';
function App() {
  return (

 <> <Pages /> </>
  );
}

export default App;
