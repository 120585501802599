import React from 'react'
import { BrandsContainer, SectionContainer } from '../../../components/sections/Container'
import { Power } from '../../../components/TuningResult/Power'
import { Torque } from '../../../components/TuningResult/Torque'
import Graph from '../../../components/TuningResult/Graph'
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
 
export const PreviousResultsData = ({ powerData, torqueData, graphData, title, description, image, alt  }) => {
  return(
<SectionContainer>
<BrandsContainer>   
<h3>{title}</h3>
<p className='pt-2 lg:pt-4'>{description}</p>
 
<img src={image} alt={alt} className=' my-4 md:my-6 lg:my-8 rounded-[16px] w-full  h-96 md:h-96 box-shadow object-cover'/>
 
  <div className='hidden lg:block'>
  <Power data={powerData}/>
  <Torque data={torqueData}/>
  <Graph data={graphData} />
  </div>

  <div className='lg:hidden block '>
                     <Swiper
                      spaceBetween={0}
                      modules={[Pagination]}
                className='mt-4  '
                      breakpoints={{
                        0: {
                          slidesPerView: 1.05,
                        },
                        330: {
                          slidesPerView: 1.2,
                        },
                        480: {
                          slidesPerView: 1.3,
                        },
                        648: {
                          slidesPerView: 1.8,
                          centeredSlides: false,
                        },
                       992: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                      }}
                    >
                      <SwiperSlide  >
                        <div className=' mx-3'>
                      <Power data={powerData}/>
                      </div>
                      </SwiperSlide >
                      <SwiperSlide  >
                        <div className=' mx-3'>
                      <Torque data={torqueData}/>
                      </div>
                      </SwiperSlide>
                      <SwiperSlide  >
                        <div className=' mx-3'>
                      <Graph data={graphData} />
                      </div>
                      </SwiperSlide>
                     
                      </Swiper>
    </div>
           </BrandsContainer>
           </SectionContainer>

  )}