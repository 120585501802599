import React from 'react'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
import { ContainerInside } from '../../components/sections/Container'
 import { contact } from '../../data/contact'
 import CTA from './CTA'
import ContactForm from './ContactForm'
import { SectionContainer } from '../../components/sections/Container'
import Footer from '../../components/footer/Footer'
import GTM from '../../googletagmanager/GTM'
import { ContactHelmet } from '../../preclicktags/PreClick'
const Contact = () => {
  return (
 <>
   <GTM/>
   <ContactHelmet/>
 <DefaultNav/>
 < div className='bg-dasOffWhite flex flex-col min-h-screen' >
 <div className='flex-grow'>

 <ContainerInside>
  
  {contact.map((val)=>(
       <article className='overflow-x-hidden  min-65 '>
        <div className='px-4  sm:px-6 lg:px-16  '>
       <h1 class="relative inline-block">{val.title}</h1>
        <p className='pt-3 lg:pt-3'>{val.desc}</p>
        </div>
      <SectionContainer>
        <div className="flex  items-stretch  gap-y-8  flex-col lg:flex-row md:gap-x-8   ">
        <div class="flex-grow w-full lg:w-1/2 sm:px-16 px-0 lg:px-0">
        <h2 class="relative inline-block py-2">{val.sub1}</h2>
        <CTA/>
        </div>
      <div class="flex-grow w-full lg:w-1/2 sm:px-16 px-0 lg:px-0">
      <h2 class="relative inline-block py-2  ">{val.sub2}</h2>
      <ContactForm/> 
      </div>
      </div>
      </SectionContainer>
      </article>
      ))}
 </ContainerInside>
 </div>
 <Footer/>
 </div>
 </>
  )
}

export default Contact
