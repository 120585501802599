import React from "react";

export const Container = ({ children }) => {
  return (
    <div className="container max-w-7xl mx-auto px-3 sm:px-8 lg:px-12">{children}</div>
  );
};

export const ContainerInside = ({ children }) => {
  return (
    <div className="  pt-20 max-w-7xl mx-auto lg:pt-16  px-4 lg:my-16 my-12  sm:px-6 lg:px-16 ">{children}</div>
  );
};

export const SectionContainer = ({ children }) => {
  return (
    <div className=" container max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 xl:px-24 pt-8 md:pt-12 2xl:pt-16  lg:pt-12">{children}</div>
  );
};
export const BrandsContainer = ({ children }) => {
  return (
    <div className="xl:max-w-3xl 3xl:max-w-3xl 4xl:max-w-4xl lg:max-w-2xl">{children}</div>
  );
};
export const BannerBrandsContainer = ({ children }) => {
  return (
    <div className=" container max-w-7xl mx-auto px-0 lg:px-16 lg:pt-16 pt-8 sm:pt-16 xl:px-24">{children}</div>
  );
};
export const BannerContainer = ({ children }) => {
  return (
    <div className="container pt-8 md:pt-12 max-w-7xl mx-auto px-0 lg:px-16 lg:pt-32 xl:px-24">{children}</div>
  );
};
export const ArticleContainer = ({ children }) => {
  return (
    <div className="max-w-5xl mx-auto ">{children}</div>
  );
};

export const OtherBrandsContainer = ({ children }) => {
  return (
    <div className=" container max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 xl:px-24 pt-4 ">{children}</div>
  );
};
export const SectionContainerArticle = ({ children }) => {
  return (
    <div className=" container max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 xl:px-24 pt-4 md:pt-5 2xl:pt-8  lg:pt-6">{children}</div>
  );
};