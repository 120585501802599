import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

function Card({ text }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const isSmallScreen = useMediaQuery({ query: '(max-width: 580px)' });
    const isMediumScreen = useMediaQuery({ query: '(min-width: 581px) and (max-width: 991px)' });
    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });
  
    let truncateLength = 240;
    if (isSmallScreen) truncateLength = 80;
    else if (isMediumScreen) truncateLength = 100;
    else if (isLargeScreen) truncateLength = 110;

  const truncateText = (str, maxLength) => str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  return (
    <div className="">
      <p>{isExpanded ? text : truncateText(text, truncateLength)}</p>
      <button className="text-dasRed" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "Read less" : "Read more"}</button>
    </div>
  );
}

export default Card