import React from 'react'
import { BannerBrandsContainer, BannerContainer, BrandsContainer, SectionContainer } from '../../../components/sections/Container';

const IntroDesk = ({title1, image1, alt1, description1, title2, alt2, image2, description2}) => {

    return (

 <>
 <div className='hidden lg:block'>
         <SectionContainer>
        <BrandsContainer>

         <div className="relative lg:pt-16  w-full">   
        <img src={image1} alt={alt1} className='lg:rounded-[16px] lg:h-96 object-cover'/>
              <h2 className=' pt-5'>{title1}</h2>
              <p className=' pt-2  '>{description1}</p>
          </div>
          </BrandsContainer>
    </SectionContainer>
        <SectionContainer>
        <BrandsContainer> 
        <div className="relative  w-full">   
        <img src={image2} alt={alt2} className='lg:rounded-[16px] lg:h-96 object-cover'/>
              <h2 className=' pt-8'>{title2}</h2>
              <p className=' pt-2  '>{description2}</p>
          </div>

          </BrandsContainer>
    </SectionContainer>
    </div>
    </>
  
       
    );
  };

export default IntroDesk
