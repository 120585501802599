import React, {useState, useEffect, useRef} from 'react';
import { BsFillRecordFill, BsRecordCircle } from 'react-icons/bs';
import Introduction from '../../pages/home/Introduction';
import FeaturedBrands from '../../pages/home/FeaturedBrands';
import TuningResult from '../../pages/home/TuningResult';
import { Container } from '../sections/Container';
import StartTuning from '../../pages/home/StartTuning';
import HomeBlog from '../../pages/home/HomeBlog';
import Clients from '../../pages/home/Clients';
  
const ScrollButton = ({ target, isClicked, hoverText }) => {
  const [isHovered, setIsHovered] = useState(false);

    const handleClick = () => {
      const element = document.getElementById(target);
      if (element) {
        const offset = 70; 
        const top = element.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    };
    return (
      <>
       <div 
      className='relative lg:mb-5 xl:mb-8 ml-0.5 2xl:mb-9 -mt-1 3xl:mb-12 4xl:mb-20 mb-4 text-dasGray' 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && <div className='absolute top-0 left-0 transform -translate-x-full -translate-y-1/2 text-black px-2 mt-3 '>{hoverText}</div>}
      <button onClick={handleClick} className='relative hover:text-dasBlack'>
        {isClicked  ? <h3  className='text-dasRed'><BsFillRecordFill /></h3> : < h3><BsFillRecordFill /></h3>} 
      </button>
    </div>
    
    </>
    ); 
  };
  
  const Scroll = ({ targetDivRef }) => {
    const [currentSection, setCurrentSection] = useState("section1");
    const [showButtons, setShowButtons] = useState(true);

    const handleScroll = () => {
      const scrollPosition = window.scrollY + 140;
      const section1 = document.getElementById("section1");
      const section2 = document.getElementById("section2");
      const section3 = document.getElementById("section3");
      const section4 = document.getElementById("section4");
      const section5 = document.getElementById("section5");
      const section6 = document.getElementById("section6");
  
      // Added null checks and default offsetTop value
      const offset1 = section1 ? section1.offsetTop :Infinity;
      const offset2 = section2 ? section2.offsetTop : Infinity;
      const offset3 = section3 ? section3.offsetTop : Infinity;
      const offset4 = section4 ? section4.offsetTop : Infinity;
      const offset5 = section5 ? section5.offsetTop : Infinity;
      const offset6 = section6 ? section6.offsetTop : Infinity;
  
      if (scrollPosition >= offset1 && scrollPosition < offset2) {
        setCurrentSection("section1");
      } else if (scrollPosition >= offset2 && scrollPosition < offset3) {
        setCurrentSection("section2");
      } else if (scrollPosition >= offset3 && scrollPosition < offset4) {
        setCurrentSection("section3");
      } else if (scrollPosition >= offset4 && scrollPosition < offset5) {
        setCurrentSection("section4");
      } else if (scrollPosition >= offset5 && scrollPosition < offset6) {
        setCurrentSection("section5");
      } else if (scrollPosition >= offset6) {
        setCurrentSection("section6");
      }
      const contactForm = document.getElementById("home-contact");
const contactOffset = contactForm ? contactForm.offsetTop : Infinity;
if (scrollPosition >= contactOffset) {
  setShowButtons(false);
} else {
  setShowButtons(true);
}

    };
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return (
      <>
      <Container>
      {
  showButtons && (
      <div className="flex flex-col items-center fixed z-8 bottom-96  h-60 xl:bottom-60 2xl:bottom-80 3xl:bottom-82 4xl:bottom-100 4xl:h-33  xl:h-72 right-2 xl:right-4 3xl:right-8 3xl:h-96 2xl:h-80 before:content-[''] before:absolute before:inset-0 before:border-l-2 before:border-dasGray before:w-0  before:z-negative before:left-2/4 before:transform[-translate-x-2/4]">
        <ScrollButton target="section1" isClicked ={currentSection === "section1"} hoverText="Intro" />
        <ScrollButton target="section2" isClicked ={currentSection === "section2"} hoverText="Brands"/>
        <ScrollButton target="section3" isClicked ={currentSection === "section3"} hoverText="Results"/>
        <ScrollButton target="section4" isClicked ={currentSection === "section4"} hoverText="Tuning"/>
        <ScrollButton target="section5" isClicked ={currentSection === "section5"} hoverText="Blogs"/>
        <ScrollButton target="section6" isClicked ={currentSection === "section6"} hoverText="Reviews"/>
       </div>
  )
  }
      <section id="section1" className=" ">
        <Introduction targetDivRef={targetDivRef} />
      </section>
      <section id="section2" className=" ">
        <FeaturedBrands />
      </section>
      <section id="section3" className=" ">
        <TuningResult />
      </section>
      <section id="section4" className=" ">
        <StartTuning />
      </section>
      <section id="section5"  >
        <HomeBlog />
      </section>
      <section id="section6">
        <Clients/>
      </section>
    </Container>
      
       </>
    );
  };
  export default Scroll;
