import React from 'react'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
import { ContainerInside, SectionContainer } from '../../components/sections/Container'
import { SecondaryButton } from '../../components/buttons/secondary-button/SecondaryButton'
import { secondary } from '../../data/components/button'
import Animation from './Animation'
import Footer from '../../components/footer/Footer'
import GTM from '../../googletagmanager/GTM'
import { ThankyouHelmet } from '../../preclicktags/PreClick'

const ThankYou = () => {
  
  return (
<>
 
<GTM/>
<ThankyouHelmet/>
<DefaultNav/>
< div className='bg-dasOffWhite flex flex-col min-h-screen' >
  <div className='flex-grow'> 
<ContainerInside>
  <SectionContainer>
<article className='flex-grow   '>
<div className='p-8 my-auto md:w-3/4 lg:w-3/4 3xl:w-4/4  flex  flex-col align-middle mx-auto text-center flex-grow  rounded-[16px] box-shadow bg-normalWhite  text-dasGrayDark'>
<Animation/>
<h4 className='pt-3 lg:pt-6 pb-1'>Submitted successfully</h4>
<p>Thanks for your message, we will get back to you shortly.</p>
<article className='mx-auto text-center pt-5'>
<SecondaryButton  buttonText={secondary[2].buttonText}
redirect="/contact-us"/>  
</article>
</div>
</article>
</SectionContainer>
</ContainerInside>
</div>
    <Footer/>
    </div>
 
</>
  )
}

export default ThankYou
