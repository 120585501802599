import React from "react";
import { useState } from "react";
import { MdEmail, MdMailOutline } from "react-icons/md";
import { BsFacebook, BsInstagram, BsYoutube, BsWhatsapp  } from "react-icons/bs";
export const Facebook = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClickfacebook = () => {
    window.open("https://www.facebook.com/dastuning1/", "_blank");
  };

  return (
    <>
      <div
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    role="button"
    onClick={handleClickfacebook}
    id="facebook"
     className="text-normalWhite text-2xl hover:text-dasRed focus:text-dasRed active:text-dasRed"
  >
    <BsFacebook />
  </div>
    </>
  );
};
export const Whatsapp = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClickWhatsapp = () => {
    window.open("https://wa.me/message/UIUAMHPZQRFAC1", "_blank");
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClickWhatsapp}
        id="whatsapp"
         className="text-normalWhite text-2xl hover:text-dasRed focus:text-dasRed active:text-dasRed"
        >
          <BsWhatsapp />
        </div>
    </>
  );
};
export const Instagram = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClickInstagram = () => {
    window.open(" https://www.instagram.com/dastuningae/", "_blank");
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClickInstagram}
        id="instagram"
         className="text-normalWhite text-2xl hover:text-dasRed focus:text-dasRed active:text-dasRed"
  >
    <BsInstagram />
  </div>
    </>
  );
};
export const Youtube = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClickYoutube = () => {
    window.open(
      "https://www.youtube.com/channel/UCqXz-6pZ-OQw9u8IsqLsmHg/featured  ",
      "_blank"
    );
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClickYoutube}
        id="linkedin"
        className="text-normalWhite text-2xl hover:text-dasRed focus:text-dasRed active:text-dasRed"
        >
          <BsYoutube />
        </div>
    </>
  );
};

 
 
