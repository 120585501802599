import React from "react";
import { useState, useEffect } from "react";
import { BsWhatsapp } from "react-icons/bs";
export const SecondaryButton = ({ buttonText, redirect }) => {
    const [redirectTo, setRedirectTo] = useState(null);
  
    useEffect(() => {
      if (redirectTo) {
        window.location.href = redirectTo;
      }
    }, [redirectTo]);
  
    function handleClick() {
      setRedirectTo(redirect);
    }
  
    return (
      <div>
        <>
          <button
            className=" bg-transparent   text-dasRed  border-2 border-solid hover:bg-secondaryHover active:bg-secondaryactive border-dasRed p-2 px-2 sm:p-3 sm:px-5 rounded-lg  my-auto  "
            onClick={handleClick}
          >
            <text className="my-auto button-text"><p>{buttonText}</p></text>
          </button>
        </>
      </div>
    );
  };

  export const SecondaryButtonIcon = ({ buttonText}) => {
 
    const handleClickWhatsapp = () => {
      window.open("https://wa.me/message/UIUAMHPZQRFAC1", "_blank");
    };
    return (
      <div>
        <>
          <button
            className=" bg-transparent w-full   text-dasRed  border-2 border-solid hover:bg-secondaryHover active:bg-secondaryactive border-dasRed p-2 px-2 sm:p-3 sm:px-5 rounded-lg  my-auto  "
            onClick={handleClickWhatsapp}
          >
            <div className="flex  justify-center flex-row gap-x-3">
<h4 className="my-auto"><BsWhatsapp/></h4>
 <h6>{buttonText}</h6>
            </div>

    
          </button>
        </>
      </div>
    );
  };