import React, { useState, useEffect, useRef } from 'react';
import { logo } from "../../../data/components/logo"; 
import { brands,  nav_items } from "../../../data/components/nav";
import {  MenuButton, NavButtonTrans } from "../../buttons/nav-button/NavButton";
import { nav_button } from "../../../data/components/button";
import { BsChevronDown, BsChevronUp, BsList, BsXLg } from "react-icons/bs";
import { Facebook, Instagram, Whatsapp, Youtube } from '../../buttons/social-buttons/SocialLinks';
import {Container} from '../../sections/Container';
import logo_White from '../../../images/logo/das-tuning-car-tuning-services-dubai-logo.png'
const TransparentNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const [isClicked, setIsClicked] = useState(false);
  const [isClickedMob, setIsClickedMob] = useState(false);
const dropdownRef = useRef(null);
const dropdownMobRef = useRef(null);
useEffect(() => {
  // Close dropdown menu when clicked outside
  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsClicked(false);
    }
  }
  window.addEventListener('click', handleClickOutside);
  return () => {
    window.removeEventListener('click', handleClickOutside);
  };
}, [dropdownRef]);
useEffect(() => {
  // Close dropdown menu when clicked outside
  function handleClickOutside(event) {
    if (dropdownMobRef.current && !dropdownMobRef.current.contains(event.target)) {
      setIsClickedMob(false);
    }
  }

  window.addEventListener('click', handleClickOutside);
  return () => {
    window.removeEventListener('click', handleClickOutside);
  };
}, [dropdownMobRef]);
  return (
    <>
    <nav className="bg-gradient-to-b from-black from-8% z-30 fixed top-0 w-full  to-transparent to-100% ">
 <Container>
      <div className="relative flex items-center justify-between h-20">
        {/* Logo */}
        {logo.map((val)=>(
        <a className="flex-shrink-0 flex items-center" href='/'>
          <img
            className="block   h-10 md:h-12 w-auto"
            src={ logo_White}
            alt="The logo of Das Tuning, a premium car tuning service from Dubai’s leading automotive experts."
          />
        </a>
))}
        {/* Hamburger Menu */}
       <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
  <button
    type="button"
    className="inline-flex items-center justify-center text-normalWhite focus:outline-none"
    onClick={toggleNavbar}
  >
    <span className="sr-only">Open main menu</span>
    {isOpen ? (
      <BsXLg className="block h-8 md:h-12 w-8" />
    ) : (
      <BsList className="block h-8 w-8" />
    )}
  </button>
</div>
<div className="block sm:hidden  mr-12 sm:mr-0">
<NavButtonTrans
                buttonText={nav_button[0].buttonText}
                redirect="/contact-us"
              /> 
  </div>
        {/* Navbar Items */}
        <div className="hidden sm:block sm:ml-6">
          <div className="flex space-x-5">
          <div className="relative" ref={dropdownRef}>
          <p onClick={() => setIsClicked(!isClicked)} className="text-normalWhite  py-3 flex items-center" role='button'>
  <span className="mr-2">Brands</span>
  
  {isClicked ? ( 
    <p><BsChevronUp/></p> 

  ) : ( 
    <p><BsChevronDown/></p>
  )}
</p>

      {isClicked && (
        <>
      <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-overlayNav"onClick={() => setIsClicked(!isClicked)}></div>
        <div className="z-30 absolute left-0 mt-2 w-48 rounded-b-xl shadow-lg text-dasGray bg-transparent">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {brands.map((item) => (
              <a href={item.onclick} className="block px-4 py-2  " role="menuitem">
                {item.brand}
              </a>
            ))}
          </div>
        </div>
      </>
      )}
    </div>
{nav_items.map((val)=>(
     <a
     href={val.onclick}
     className="text-normalWhite  py-3   "
   >
  <p>{val.item}</p> 
   </a>
))}
            <NavButtonTrans
                buttonText={nav_button[0].buttonText}
                redirect="/contact-us"
              /> 
          </div>
        </div>
      </div>

{/* Off-canvas Menu */}
<div
  className={`${
    isOpen ? "block" : "hidden"
  } fixed inset-0 z-0 bg-dasBlackTrans   sm:hidden  `}
>
    <div className="fixed inset-0 z-50 bg-normalBlack   xs:ml-16 s:ml-24">
  <div className="flex items-center justify-between px-4 pt-6">
    <p className="h-8 w-auto text-normalWhite ps-3"   >Menu</p>
    <button
      type="button"
      className="inline-flex items-center justify-center  rounded-md text-normalWhite focus:outline-none  "
      onClick={toggleNavbar}
    >
      <BsXLg className="block h-4 w-4" />
    </button>
  </div>
  <div className="   pb-3 ">
  <ul className=" text-normalWhite block ps-3 pt-2 rounded-md text-base  "> 
  <li className='  px-3 py-2'><a href= "/"><h4>Home</h4></a></li>
  <ul className=" text-normalWhite block px-3   rounded-md text-base  "> 
        {nav_items.map((item ) => (
        <li className=' py-2'><a href={item.onclick} ><h4>{item.item}</h4></a></li>
        ))}
      </ul>
 <div className="relative px-3 py-2" ref={dropdownMobRef}>
          <p onClick={() => setIsClickedMob(!isClickedMob)} className="text-normalWhite flex items-center justify-between" role='button'>
  <h4 >Brands</h4>
  {isClickedMob ? (     
    <p><BsChevronUp/></p>
  ) : ( 
    <p>< BsChevronDown/></p>
  )}
</p>
      {isClickedMob && (
        <div className="absolute left-0  w-48 rounded-b-xl shadow-lg text-dasGray bg-normalBlack">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {brands.map((item) => (
              <a href={item.onclick} className="block px-4 py-2  " role="menuitem">
                {item.brand}
              </a>
            ))}
          </div>
        </div>
      )}
      
            </div> 
      </ul>
  </div>
  <div class="absolute bottom-0  py-2  left-1/2 transform -translate-x-1/2">
  <MenuButton
  buttonText={nav_button[0].buttonText}
  redirect="/contact-us"
  />
  <div className="flex justify-between px-2 py-5">
    <Facebook/>
    <Instagram/>
    <Whatsapp/>
    <Youtube/>
  </div>
  </div>
</div>
</div>
</Container>
            </nav>
        
            </>
  )
}

export default TransparentNav
