import React from 'react'
import { Facebook, Instagram, Whatsapp, Youtube } from './SocialLinks'

const AllSocialButtons = () => {
  return (
    <div>
      <div className="flex justify-between px-2 py-5">
    <Facebook/>
    <Instagram/>
    <Whatsapp/>
    <Youtube/>
  </div>
    </div>
  )
}

export default AllSocialButtons
