import React, { useState } from "react";
import { BsShare, BsWhatsapp, BsFacebook, BsTwitter } from "react-icons/bs";

const ShareButton = ({ articleUrl, articleTitle }) => {
  const [showIcons, setShowIcons] = useState(false);

  const generateShareLink = (platform) => {
   
    let shareLink = "";

    switch (platform) {
      case "whatsapp":
        shareLink = `https://wa.me/?text=${encodeURIComponent(
          articleTitle + " " + articleUrl
        )}`;
        break;
      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          articleUrl
        )}`;
        break;
        case "twitter":
          shareLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            articleTitle
          )}&url=${encodeURIComponent(articleUrl)}`;
          break;
      
      default:
        break;
    }

    return shareLink;
  };

  const handleShareClick = (platform) => {
    const shareLink = generateShareLink(platform);
    window.open(shareLink, "_blank");
  };

  return (
    <div className="relative inline-block">
      {showIcons && (
        <div
          className="absolute d-flex "
          style={{ right: "100%", top: "0", alignItems: "center", background:"#808080", color:"#f2f2f2",  borderRadius:"16px" }}
        >
          <BsWhatsapp
            onClick={() => handleShareClick("whatsapp")}
            style={{ cursor: "pointer", margin:"16px" }}
          />
          <BsFacebook
            onClick={() => handleShareClick("facebook")}
            style={{ cursor: "pointer", margin:"16px" }}
          />
           <BsTwitter
            onClick={() => handleShareClick("twitter")}
            style={{ cursor: "pointer", margin:"16px" }}
          />
        </div>
      )}
      <BsShare onClick={() => setShowIcons(!showIcons)} className="cursor-pointer"/>
    </div>
  );
};

export default ShareButton;
