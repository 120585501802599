import React from 'react'
import { about } from '../../data/about'
import { SectionContainer } from '../../components/sections/Container'
 

const AboutIntro = () => {
  return (
 <>
 {about.map((val)=>(
  <SectionContainer>
    <div className="flex md:flex-row flex-col gap-x-6  justify-between">
    <div className="md:w-5/12 lg:w-5/12 w-full mt-6 md:mt-0 order-last md:h-80 md:order-first flex justify-center rounded-[16px] align-top">
    <img src={val.img1} alt={val.alt1} className='rounded-[16px] object-cover  '/>
    </div>
    <div className="md:w-7/12 lg:w-7/12 w-full order-first md:order-last md:pe-3">
    <h1 class="relative inline-block pb-2 ">{val.title}</h1>
    <p>{val.desc1}</p>
    <p className='pt-1 lg:pt-2'>{val.desc2}</p>
    </div>
    </div>
        </SectionContainer>
        ))}
        </>
  )
}

export default AboutIntro
