import React from 'react'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
import { ContainerInside, SectionContainer } from '../../components/sections/Container'
import img1 from '../../images/404/das-tuning-car-tuning-services-error-404-page-not-found.png'
import { error } from '../../data/error'
import { SecondaryButton } from '../../components/buttons/secondary-button/SecondaryButton'
import { secondary } from '../../data/components/button'
import Footer from '../../components/footer/Footer'
import GTM from '../../googletagmanager/GTM'
import { NotFoundHelmet } from '../../preclicktags/PreClick'
const NotFound = () => {
  return (
    <>
      <GTM/>
      <NotFoundHelmet/>
<DefaultNav/>
< div className='bg-dasOffWhite flex flex-col min-h-screen' >
  <div className='flex-grow'>
<ContainerInside>
  <SectionContainer>
    {error.map((val)=>(
<article className='  text-center mx-auto flex flex-col justify-center'>
<img src={img1} alt={val.alt} className='w-2/4  flex mx-auto  justify-center'/>
<p className=' py-4 lg:py-6'>{val.desc}</p>

<SecondaryButton 
buttonText={secondary[4].buttonText}
redirect="/"/> 
    </article>
    ))}
 
    </SectionContainer>
    </ContainerInside>
    </div>
    <Footer/>
    </div>
    </>
  )
}

export default NotFound
