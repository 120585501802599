import React from 'react'
import { reachus } from '../../../data/components/reach'

export const Call = () => {
  return (
    <div> 
    {reachus.map((val) => (
      <>   
      <a href="tel:+971-50-752-6677"  className="flex justify-between text-decoration-none   ">
          <div className=" flex flex-row items-center    ">
            <h4 className="    my-auto  flex">
              {val.icon_phone}
            </h4>
            <div>
              <p className="  ps-2 ">{val.contact} </p>
            </div>
          </div>
          <div className='flex items-center  my-auto '><p >{val.icon}</p></div>
          </a>
      </>
    ))}
  </div>
  )
}

export const Mob = () => {
  return (
    <div> 
    {reachus.map((val) => (
      <>   
      <a href="tel:+971-50-515-6697"  className="flex justify-between text-decoration-none   ">
          <div className=" flex flex-row items-center    ">
            <h4 className=" my-auto  flex">
              {val.icon_phone2}
            </h4>
            <div>
              <p className="  ps-2 ">{val.contact2} </p>
            </div>
          </div>
          <div className='flex items-center  my-auto '><p >{val.icon}</p></div>
          </a>
      </>
    ))}
  </div>
  )
}

export const Email = () => {
return(
    <div className="mail">
    {reachus.map((val) => (
      <>
        <a
          href="mailto:dastuning.ae@gmail.com"
          className="flex justify-between text-decoration-none   "
        >
          <div
            role="button"
            className="flex flex-row items-center ">
            <h4 className="   my-auto  flex">{val.icon_mail}</h4>
            <p className="bold  ps-2 ">{val.mail}</p>
          </div>
          <div className='flex items-center  my-auto '><p >{val.icon}</p></div>

        </a>
      </>
    ))}
  </div>
)

}

export const Location = () => {
    const handleClick = () => {
      window.open("https://goo.gl/maps/KcjBJonHtgzwRTLAA", "_blank");
    };
  
    return (
      <div>
        {reachus.map((val) => (
          <>
            <div
              role="button"
              onClick={handleClick}
              className="flex justify-between text-decoration-none   "
            >
              <div role="button"     className="flex flex-row">
            <h4 >{val.icon_location}</h4>
                <p className="ps-2 w-10/12 md:w-full">{val.address}</p>
              </div>
              <div className='flex items-center  my-auto'><p >{val.icon}</p></div>
            </div>
          </>
        ))}
      </div>
    );
  };