import React, { useState, useRef } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import emailjs from "@emailjs/browser";
import ThankYou from "../thank-you/ThankYou";

const ContactForm = () => {
  const form = useRef();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [contactNumber, setContactNumber] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsEmailValid(emailRegex.test(event.target.value));
  };

  const handlePhoneChange = (event) => {
    setContactNumber(event.target.value);
};


  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(e.target);
    const formValues = Object.fromEntries(formData);
    console.log(formValues);
    emailjs
      .sendForm(
        "service_9gu7i4d",
        "template_fcz6wid",
        form.current,
        "tsevzYsapLKXn1dLD"
      )
      .then(() => {
        setIsSubmitted(true);
        navigate("/thank-you");
      })
      .catch((err) => {
        console.error("Failed to send email. Error: ", err);
      })
      .finally(() => {
        setIsLoading(false);
      });

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/contact`, formValues)
      .then(response => {
        console.log('Data successfully sent to server');
      })
      .catch(error => {
        console.error('Failed to send data to server. Error: ', error);
      });
  };
 

  if (isSubmitted) {
    return <ThankYou />;
  }
 

  return (
    <div className='p-8 my-2  flex-grow  rounded-[16px] box-shadow-main-banner bg-normalWhite  text-dasGray'>
        <form ref={form} 
        onSubmit={sendEmail} 
        className='flex flex-col gap-y-4'>
      
        <input 
        className='py-4 border p-5 border-dasBlack rounded-lg text-dasGrayDark focus:outline-none  ' 
        type="text"
        name="name"
        id="name"
        placeholder="Full name" 
        required />

        <input 
        className='py-4 border p-5 border-dasBlack rounded-lg text-dasGrayDark focus:outline-none  ' 
        type=" email"
        name="email"
        id="email"
        value={email}
        onChange={handleEmailChange}
        placeholder=" Email"
        required/>

     {!isEmailValid && (
            <p className="text-red-500">Please enter a valid email address</p>
          )}

      <input 
      className='py-4 border p-5 border-dasBlack rounded-lg text-dasGrayDark focus:outline-none  ' 
      type="tel"
              name="contactNumber"
              id="contactNumber"
              value={contactNumber}
              onChange={handlePhoneChange}
              placeholder=" Contact No (e.g.+971 xx xxx xxxx)"
              required
            />

         {!isPhoneValid && (
            <p className="text-red-500">Please enter a valid phone number</p>
          )}

      <textarea 
      className='py-4 h-32 sm:h-36  lg:h-28  xl:h-36      min-h-full border p-5 border-dasBlack rounded-lg text-dasGrayDark focus:outline-none  ' 
      name="message"
      id="message"
       placeholder="Message" 
      required>                                 
      </textarea>

     <div class="flex lg:justify-end  justify-center">
 
     <button
          className=" bg-transparent hover:bg-secondaryHover active:bg-secondaryactive  text-dasRed  border-2 border-solid border-dasRed w-full lg:w-3/12 p-2 px-2 sm:p-3 sm:px-5 rounded-lg  my-auto  "
          type="submit"
        >
          <text className="my-auto button-text"><p>Submit</p></text>
        </button>
     </div>
     
    </form>
     </div>
  );
};

export default ContactForm;
