export const privacy=[{
    title:"PRIVACY POLICY",
    desc:"The policy: This privacy policy notice is served by DAS Tuning under the website; www.dastuning.ae.  The purpose of this policy is to explain to you how we control, process, handle and protect your personal information through the business and while you browse or use this website. If you do not agree to the following policy you may wish to cease viewing / using this website, and or refrain from submitting your personal data to us.",
    subtitle1:"Policy key definitions",
    desc2:'“I”, “our”, “us”, or “we” refer to the business, DAS Tuning.“you”, “the user” refer to the person(s) using this website.Cookies mean small files stored on a user’s computer or device.',
    subtitle2:"Processing of your personal data",
    desc3:"Under the GDPR (General Data Protection Regulation) we control and / or process any personal information about you electronically using the following lawful bases.",
    subtitle3:"Lawful basis: Consent",
    desc4:"Where our purpose for processing is: We may collect data when you submit a contact submission form. Which is necessary to allow us to get back in contact with you at a later date. We process your information by storing it and then using it to return contact to you.",
    sub:" Data retention period",
    desc5:"We will continue to process your information under this basis until you withdraw consent, or it is determined your consent no longer exists. Sharing your information: We do not share your information with third parties."
}]



export const tandc=[
    {text:"1.	Any service/remap is bound to these terms and conditions."},
    {text:"2.	All vehicles must be in full working mechanical order and should be fully maintained/checked before coming for a remap."},
    {text:"3.	DAS Tuning reserves the right to not carry out a service/remap on any vehicle if faults are found."},
    {text:"4.	If mobile service is used, DAS Tuning reserves the right to still charge costs for coming out (Even if no service/remap is completed for any reason)"},
    {text:"5.	DAS Tuning take no responsibility for loss of radio codes, or any other vehicle data while carrying out a service/remap."},
    {text:"6.	DAS Tuning reserves the right to refuse any of its services to anyone for any, or no, reason whatsoever."},
    {text:"7.	Any reading or writing of our software on your vehicle instantly invalidates the tuning warranty (by other tuners, or with your own tools). "},
    {text:"8.	All BHP/Torque figures are approximate and will vary between vehicles."},
    {text:"9.	Any work carried out will be as per customer’s request."},
    {text:"10.	DAS Tuning do not accept liability for death, personal injury, loss of earnings, accidents, damages, faults or legal claims or any other claim during/after the service/remap under any circumstances."},
    {text:"11.	It is your responsibility to inform your insurance company of the remap."},
    {text:"12.	DAS Tuning do not offer any type of warranty for your vehicle."},
    {text:"13.	DAS Tuning do not accept responsibility for any serviceable item and/or mechanical part (including turbo’s, clutches/flywheels & engine internals) or electrical parts that fails. We accept no claim for labor and/or parts under any circumstances."},
    {text:"14.	Refunds are strictly at DAS Tuning’s discretion."},
    {text:"15.	All payments are due on completion of the work. No credit is allowed, No instalments. Invoice must be settled immediately no exceptions."},
    {text:"16.	DAS Tuning reserve the right to change or alter any of these terms and conditions on this page at any time."},
    {text:"17.	Any coping of these terms and conditions is prohibited in any way shape or form."},
]