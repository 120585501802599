import React, { useEffect, useRef, useState } from "react";
import lottie from "lottie-web";

const Animation = () => {
  const container = useRef(null);
  const [animationLoaded, setAnimationLoaded] = useState(false);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("./Thank_you.json"),
    });

    animation.addEventListener("complete", () => setAnimationLoaded(true));

    return () => {
      animation.destroy(); // optional clean up for unmounting
    };
  }, []); // Empty array ensures this runs on mount and unmount only

  return (
    <div className="w-2/4 3xl:w-3/4 mx-auto">
      <div className="container" ref={container}></div>
    </div>
  );
};

export default Animation;
