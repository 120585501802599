
import { BsPersonFillGear, BsPeopleFill, BsCheckAll, BsTools } from "react-icons/bs";

export const about=[{
    title:"About Us",
    banner_desk:"./Images/about/car-tuning-services-ferrari-porsche-dubai.png ",
    banner_mob:"./Images/about/car-tuning-services-ferrari-porsche-dubai-mob.png ",
    alt:"Enhanced red Ferrari Enzo and silver Porsche Carrera GT showcasing the exceptional car tuning services by Das Tuning in Dubai.",
    desc1:"DAS Tuning is a premier automotive specialist that focuses on bespoke software and hardware modifications for high-end sports and luxury vehicles. Specializing in BMW, Mercedes, Audi, and Volkswagen. After running a successful Fiat and Lancia tuning workshop in the United Kingdom, we relocated to Dubai, UAE, and opened a luxury European car workshop in 2013.",
    desc2:"DAS Tuning is owned and managed by Ilyas Sheikh and Baber Sheikh. The services we offer include custom dyno tuning, engine rebuilding, turbocharger upgrades, custom exhaust and fabrication, and much more. With our wide range of services, you can get the most out of your car on track and on the road. We offer customized car tuning services tailored to each client's specific needs, and we ensure that we deliver exceptional car tuning services and results to each of our clients.",
    desc3:"Car Tuning involves modifying car's engine and handling features. Tuning services are essential in order to improve the overall performance of the car. It helps in increasing your car's power and torque, enhances fuel efficiency, and makes it look more unique and tailored to the specific needs of each client. ",
    subtitle:"What is Car Tuning?",
    subtitle2:"Why DAS Tuning?",
    desc4:"With over 30 years of experience, our team of certified professionals are equipped with the knowledge and skills to provide you with the best car tuning services in Dubai and the UAE.",
    subtitle3:"What we do",
    desc5:"Experience unmatched expertise and exceptional results at DAS Tuning, where our team of highly skilled technicians is dedicated to fulfilling your car tuning requirements. Explore our extensive selection of top-tier services, meticulously crafted to elevate your driving experience to new heights.",
    img1:"./Images/about/dubai-car-tuning-owner.png",
    alt1:"Expert car tuning services in Dubai with our owner personally involved, enhancing performance and power in the workshop.",
    img2:"./Images/about/dubai-car-tuning-fabrication-services.png",
    alt2:"Dubai Car Tuning Company - High-Quality Fabrication Services for Car Tuning and Modification by Das Tuning."
}]

export const icons = [{
    icon:<BsPersonFillGear/>,
    text:"Personalized Service"
},
{
icon:<BsCheckAll/>,
text:"Results Guaranteed"
},
{icon:<BsPeopleFill/>,
text:"Solid Commitment"
},
{
icon:<BsTools/>,
text:"Certified Specialists"
}

]

export const cards=[
{
img:"./Images/about/das-tuning-dyno-testing-services-car-tuning.png",
alt:"Professional Dyno Testing Services for Car Tuning at Das Tuning in Dubai, UAE - Achieve Optimal Performance and Power.",
title:"Dyno Testing",
desc:" We offer Dyno Testing in Dubai and the UAE. We measure performance of your car’s engine and identify issues. Through this we measure engine's horsepower, torque, and air to fuel ratio."
},
{
    img:"./Images/about/custom-ecu-tuning-services-das-tuning.png",
    alt:"Enhance your vehicle's performance with our professional custom ECU tuning services in Dubai offered by Das Tuning, the leading car tuning experts.",
    title:"Custom ECU Tuning",
    desc:"Modify Engine Control Unit (ECU) of your vehicle and improve horsepower, torque, and fuel efficiency with the help of our tuning experts in Dubai and the UAE. Experience optimal performance with us. "
    },
    {
        img:"./Images/about/dubai-car-tuning-exhaust-upgrades-services.png",
        alt:"Enhance Your Ride with Premium Exhaust Upgrades: Explore Our Car Tuning Services for Unmatched Performance and Sound at Das Tuning in Dubai",
        title:"Exhaust Upgrades",
        desc:"Experience the perfect blend of sound and power with our exhaust upgrades. Trust our team of experts to assist you in selecting the ideal exhaust system for your car. Experience exhilarating power!"
        },
        {
            img:"./Images/about/dubai-car-tuning-fabrication.png",
            alt:"Car Tuning Company in Dubai Offering High-Quality Fabrication Services for Optimal Performance Enhancement.",
            title:"Fabrication",
            desc:"Best custom fabrication services in Dubai and the UAE. With advanced tools and techniques, we will help enhance the performance of your vehicle. Contact and learn more about our fabrication services."
            },
            {
                img:"./Images/about/dubai-tuning-company-engine-rebuilding-services.png",
                alt:"Expert Engine Rebuilding Services for Enhanced Performance and Power at Das Tuning, a Dubai-based Car Tuning Company.",
                title:"Engine Rebuilding",
                desc:"We provide engine rebuilding services in Dubai and the UAE.  Our team will restore your engine to its optimal performance level and deliver the results that will exceed your expectations."
                },
                {
                    img:"./Images/about/hardware-upgrades-services-das-tuning.png",
                    alt:"Enhance Your Car's Performance with Professional Hardware Upgrades by Das Tuning, a Dubai-based Car Tuning Company.",
                    title:"Hardware Upgrades",
                    desc:"Improve your car's performance with our wide range of hardware upgrades, from exhaust systems to turbochargers. Our team will help you select the best upgrade for your vehicle."
                    }
]