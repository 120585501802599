export const cookie = [{
    title:"Cookie Policy",
    desc1:"We use cookies on this website to provide you with a better user experience. We do this by placing a small text file on your device / computer hard drive to track how you use the website, to record or log whether you have seen particular messages that we display, to keep you logged into the website where applicable, to display relevant adverts or content, referred you to a third-party website. Some cookies are required to enjoy and use the full functionality of this website.",
    desc2:"We use a cookie control system which allows you to accept the use of cookies, and control which cookies are saved to your device / computer. Some cookies will be saved for specific time periods, where others may last indefinitely. Your web browser should provide you with the controls to manage and delete cookies from your device, please see your web browser options.",
    subtitle1:"Data security and protection",
    desc3:"We ensure the security of any personal information we hold by using secure data storage technologies and precise procedures in how we store, access and manage that information. Our methods meet the GDPR compliance requirement.",
    subtitle2:"Fair & Transparent Privacy Explained",
    desc4:"We have provided some further explanations about user privacy and the way we use this website to help promote a transparent and honest user privacy methodology."
}]

export const cookie_consent = [
    {
      h3: "We use cookies",
      p1: "This website uses cookies to improve user experience. By using our website you consent to all cookies in accordance with our ",
      link: " Cookie Policy. ",
  
      img: "./Images/cookie/Trustline-Digital-Cookie-Policy.svg",
      alt: "Our cookie policy is simple to comprehend and complies with privacy laws. See how our website uses cookies.",
    },
  ];