

import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import './slider.css'
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { SectionContainer } from "../../components/sections/Container";
import { blogs } from "../../data/home";
import { SecondaryButton } from "../../components/buttons/secondary-button/SecondaryButton";
import { secondary } from "../../data/components/button";
 
const HomeBlog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/posts`)
      .then(response => response.json())
      .then(data => {
        const sortedPosts = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPosts(sortedPosts);
      })
      .catch(err => console.error(err));
  }, []);
    const NextArrow = ({ onClick }) => {
        return (
          <div className="arrow next" onClick={onClick}>
            <h3> <BsChevronRight className="hidden md:block"/></h3>
          </div>
        );
      };
      const PrevArrow = ({ onClick }) => {
        return (
          <div className="arrow prev" onClick={onClick}>
          <h3><BsChevronLeft className="hidden md:block" /></h3>  
          </div>
        );
      };
      const [imageIndex, setImageIndex] = useState(0);
      const settings = {
        dot: true,
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => setImageIndex(next),
        responsive: [
          {
            breakpoint:992,  
            settings: {
              slidesToShow: 1,  
              centerMode: true,
              centerPadding: '20%',
            },
          },
          {
            breakpoint: 768, 
            settings: {
              slidesToShow: 1,   
              centerMode: true,
              centerPadding: '10%', 
            },
          },
        ],
      };
      
      
  return (
    <SectionContainer>
       <article className='overflow-x-hidden text-dasBlack'>
        <h2 class="relative inline-block  "> {blogs[0].title}</h2>
        <div class="border-b-2 border-dasBlack  lg:w-1/12 w-2/6 sm:w-2/12"></div>
        <p className='py-3 lg:py-3'>{blogs[0].desc}</p> 
    </article>

   {/** */} <div className="HomeBlog pt-3 lg:pt-5">
   <Slider {...settings}>
   {posts.map((post,  idx) => (
 
        <div className={idx === imageIndex ? "slide activeSlide" : "slide "}>
       <div className="imageContainer">
       <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className="slideImage"/>
       </div>
          <div className="absolute h-32 rounded-b-[16px]  flex justify-starts bottom-0 w-full black-shade text-normalWhite text-start">
            <div className="w-6/6 lg:pb-3 pb-2 mx-4 bottom-0 absolute">
         <text>{post.title}</text> 
            </div>
          </div>
        </div>
      ))}
    </Slider> 
  </div>
  <article className='mx-auto text-center pt-5 '>
<SecondaryButton  buttonText={secondary[5].buttonText}
                redirect="/blogs"/> 
</article>
  </SectionContainer>
  )
}

export default HomeBlog
