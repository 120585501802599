import React from 'react'
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { BrandsContainer, SectionContainer } from '../../components/sections/Container';
import { our_services, our_services_cards } from '../../data/otherBrands';
const OurServices = () => {
  return (
    <div>
        {our_services.map((val)=>(
      <SectionContainer>
           <BrandsContainer>
           <h2>{val.title}</h2>
    <p className='pt-2 lg:pt-4'>{val.p}</p>

    <div className='py-5 block md:hidden'>
<Swiper
                      spaceBetween={0}
                      modules={[Pagination]}
                      className=" "
                      breakpoints={{
                        0: {
                          slidesPerView: 1.1,
                        },
                        480: {
                          slidesPerView: 1.6,
                        },
                        768: {
                          slidesPerView: 2.5,
                          centeredSlides: false,
                        },
                       992: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                      }}
                    >
                      {our_services_cards.map((item)=>(
                        <>
                        <SwiperSlide className='px-1 md:px-1'>
                        <div className='flex flex-col flex-grow px-1 md:px-5'>
                        <div className="  border  min-h-full border-dasBlack h-30 sm:h-35 text-normalBlack p-5 my-2 rounded-[16px] "> 
                        <div>
                           <img src={item.img} alt={item.alt} className='rounded-[16px]'/></div>
                            <div className="flex flex-col justify-start pt-8">
                            <h5 >{item.title}</h5>
                            <p className='text-dasGrayDark pt-3'>{item.desc}</p>     
                            </div>                  
                          </div>
                          </div> 
                        </SwiperSlide>
                        </>
                      ))}
                      </Swiper> 
                      </div>

<article className='py-5 md:block  hidden'>
{our_services_cards.map((item)=>(
<div className='flex flex-row flex-grow px-1 md:px-5'>
                        <div className="flex flex-row   border h-auto  border-dasBlack  text-normalBlack p-4 my-2 rounded-[16px] "> 
                        <div className='flex flex-row gap-x-6'>
                        <div className='w-5/12 '>
                           <img src={item.img} alt={item.alt} className='rounded-[16px]'/>
                           </div>
                            <div className="w-7/12 flex flex-col ">
                            <h5 >{item.title}</h5>
                            <p className='text-dasGrayDark pt-3'>{item.desc}</p>     
                            </div>      
                            </div>            
                          </div>
                          </div> 
                    ))}          

</article>
    </BrandsContainer>
    </SectionContainer>
    ))}
    </div>
  )
}

export default OurServices
