import { BsTelephone, BsGoogle } from "react-icons/bs";


export const nav_button = [
    {
      buttonText: "Start tuning",
    },
    {
      buttonText: "Accept",
    },
];
export const primary =[
  {
    buttonText: "Review us",
    buttonIcon: <BsGoogle/>,
  },
]

  export const secondary = [
    {
    buttonText: "Start tuning",
  },
  {
    buttonText: "Chat on WhatsApp !",
  },
  {
    buttonText: "Done",
  },
  {
    buttonText: "WhatsApp",
  },
  {
    buttonText: "Go to homepage",
  },
  {
    buttonText: "All blogs",
  },
]

export const tertiary = [{
  buttonText: "+971 50 515 6697",
  buttonIcon:<BsTelephone/>
}]

export const brandsFloating = [
{
    logo:"./Images/automakers-logo/audi-car-tuning-das-tuning.png",
    logowhite:"./Images/automakers-logo/white/audi-car-tuning-das-tuning.png",
    alt:"Experience the ultimate Audi car tuning services at Das Tuning. The iconic Audi logo representing precision, performance, and luxury.",
  brandTitle:"Audi",
  brandDesc:"Interested in tuning your Audi?"
},
{
  logo:"./Images/automakers-logo/bmw-logo-car-tuning-das-tuning.png",
  logowhite:"./Images/automakers-logo/white/bmw-logo-car-tuning-das-tuning.png",
  alt:"Unlock the full potential of your BMW with exceptional car tuning services at Das Tuning. Immerse in the iconic BMW logo symbolizing performance, elegance, and innovation.",
brandTitle:"BMW",
brandDesc:"Interested in tuning your BMW?"
},
{
  logo:"./Images/automakers-logo/mercedes-logo-car-tuning-das-tuning.png",
  logowhite:"./Images/automakers-logo/white/mercedes-logo-car-tuning-das-tuning.png",
  alt:"Experience the epitome of luxury and performance with Mercedes car tuning services by Das Tuning. Embrace the iconic Mercedes logo, a symbol of elegance, power, and craftsmanship.",
brandTitle:"Mercedes Benz",
brandDesc:"Interested in tuning your Mercedes Benz?"
},
{
  logo:"./Images/automakers-logo/volkswagen-car-tuning-das-tuning.png",
  logowhite:"./Images/automakers-logo/white/volkswagen-car-tuning-das-tuning.png",
  alt:"Unlock the full potential of your Volkswagen with professional car tuning services by Das Tuning. Embrace the iconic Volkswagen logo, a symbol of reliability, innovation, and driving pleasure. ",
brandTitle:"Volkswagen",
brandDesc:"Interested in tuning your Volkswagen?"
},
{
  logo:"./Images/other-brands/das-tuning-dubai-land-rover-ferrari-lamborghini-logos.png",
  alt:"Our expertise extends to renowned brands like Land Rover, Ferrari, and Lamborghini, delivering exceptional car tuning services.",
brandTitle:"Other brands",
brandDesc:"Interested in tuning your car?"
},
 
]