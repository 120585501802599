import React from 'react'
import GridSection from './components/GridSection'
import { grid_section, previous_results, subSection, intro } from '../../data/featuredBrands'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
import SubSection from './components/SubSection';
import {  PreviousResultsData} from './components/PreviousResults';
import { MercedesPower, MercedesTorque, graphForMercedes } from '../../data/components/tuning';
import Footer from '../../components/footer/Footer';
import { brandsFloating } from '../../data/components/button';
import { BrandsFloatingDesktop } from '../../components/buttons/floating-button/BrandsFloating';
import IntroMob from './components/IntroMob';
import { MobFloatingButton } from '../../components/buttons/floating-button/FeaturedFloatingButtons';
import IntroDesk from './components/IntroDesk';
import GTM from '../../googletagmanager/GTM'
import { MercedesHelmet } from '../../preclicktags/PreClick';

 const Mercedes = () => {
    return (
        <>
        <GTM/>
        <MercedesHelmet/>
        <DefaultNav/>
        <BrandsFloatingDesktop 
         logo={brandsFloating[2].logo} 
         alt={brandsFloating[2].alt}
        brandTitle={brandsFloating[2].brandTitle}
        brandDesc={brandsFloating[2].brandDesc}
         />
    <MobFloatingButton
    logo={brandsFloating[2].logowhite}
    alt={brandsFloating[2].alt}
    />
      <IntroDesk
        image1={intro.mercedes.image1}
        alt1={intro.mercedes.alt1}
        title1={intro.mercedes.title1}
        description1={intro.mercedes.description1}
        title2={intro.mercedes.title2}
        image2={intro.mercedes.image2}
        alt2={intro.mercedes.alt2}
        description2={intro.mercedes.description2}
        />
        <IntroMob
        image1={intro.mercedes.image1}
        alt1={intro.mercedes.alt1}
        title={intro.mercedes.title1}
        desc={intro.mercedes.description1}
        title2={intro.mercedes.title2}
        image2={intro.mercedes.image2}
        alt2={intro.mercedes.alt2}
        desc2={intro.mercedes.description2}
        />
        <GridSection 
          title1={grid_section.mercedes.title1}
          description1={grid_section.mercedes.description1}
          image1={grid_section.mercedes.image1}
          alt1={grid_section.mercedes.alt1}
          title2={grid_section.mercedes.title2}
          description2={grid_section.mercedes.description2}
          image2={grid_section.mercedes.image2}
          alt2={grid_section.mercedes.alt2}
        />
                  <SubSection
                    title={subSection.mercedes.title}
                    description={subSection.mercedes.description}
                    image={subSection.mercedes.image}
                    alt={subSection.mercedes.alt}
                  />
        <PreviousResultsData
        title={previous_results.mercedes.title}
        description={previous_results.mercedes.description}
        image={previous_results.mercedes.img}
        alt={previous_results.mercedes.alt}
        powerData={MercedesPower}
        torqueData={MercedesTorque}
        graphData={graphForMercedes}/>
        <Footer/>
        </>
           )
 }
 
 export default Mercedes
 