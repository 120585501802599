import React, {  useState} from 'react'
import { SectionContainer } from '../../components/sections/Container'
import { client_reviews, clients } from '../../data/home'
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from 'react-responsive';
import SwiperCore, { Pagination, Navigation } from "swiper";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import './slider.css'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import {   PrimaryButtonIcon } from '../../components/buttons/primary-button/PrimaryButton';
import { primary } from '../../data/components/button';
import Card from '../../utils/readMore';

// import required modules
SwiperCore.use([Pagination, Navigation]);
const Clients = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
    const googleReview = () => {
      window.open("https://g.page/r/Ce0N-xD6BmpNEBM/review", "_blank");
    };
     
    const [swiper, setSwiper] = useState(null);

  return (
    <SectionContainer  >
    <article className='overflow-x-hidden      '>
    <h2 class="relative inline-block">{ clients[0].title}</h2>
    <div class="border-b-2 border-dasBlack  lg:w-1/12 w-2/6 sm:w-2/12"></div>
    <p className='py-3 lg:py-3'>{clients[0].desc}</p>
</article>

<article className='flex relative items-stretch'>
 
      <Swiper
        slidesPerView={3}
        className='swiper-clients'
        onSwiper={setSwiper}
        spaceBetween={25}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        breakpoints={{
          0: {
            slidesPerView: 1.2,
          },
          480: {
            slidesPerView: 2,
          },
          630: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2.5,
            centeredSlides: false,
          },
         992: {
            slidesPerView: 3,
            centeredSlides: false,
          },
        }}
     >
    
        {client_reviews.map((review, index) => (
          <SwiperSlide className='py-3 flex flex-grow'> 
            <div className="card text-start rounded-[16px] box-shadow sm:py-3 p-3 sm:p-3 md:p-5 lg:p-5  lg:h-auto min-h-full  justify-center">
              <div className="flex flex-row gap-3 py-3">
                <div className='w-3/12 sm:w-1/4'>
                  <img src={review.img} alt={review.alt}/>
                </div>
                <div>
                  <h4>{review.name}</h4>
                  <div className="flex flex-row">
                    {review.star.map((icons, index)=>(
                      <h4 className='text-starYellow pt-1'>{icons.icon}</h4>
                    ))}
                  </div>
                </div>
              </div>
              <p>
                <Card text= {review.desc}/>
              </p>
            </div>
          </SwiperSlide>
        ))}
      

      </Swiper>
    
      {!isMobile && (
          <>
  <div className="swiper-button-next absolute right-0 -mr-8">
      <h2 className='text-dasBlack'><BsChevronRight /></h2>
    </div>
    <div className="swiper-button-prev absolute left-0 -ml-10">
      <h2 className='text-dasBlack'><BsChevronLeft /></h2> 
    </div>
          </>
        )}
</article>


{/**Google Review */}
 <article>
  <SectionContainer>
{clients.map((item)=>(
<div
    onClick={googleReview}
    role='button'
  className='border w-11/12 sm:w-9/12 md:w-8/12 lg:w-7/12 xl:w-7/12 2xl:w-7/12 mx-auto p-1 py-5 sm:py-1 rounded-[16px] border-dasBlack md:p-3 flex flex-col sm:flex-row justify-between'>
 <div className="flex sm:gap-x-4 gap-x-2 mx-auto py-2 sm:py-0">
 <div className='my-auto mx-auto'>
  <img src={item.roundLogo} alt={item.logoalt} className='my-auto' />
 </div>
 <div className="flex flex-col my-auto">
  <h3>DAS Tuning</h3>
  <img src={item.google} alt={item.alt} />
 </div>
 </div>
 <div className='block sm:hidden py-2'>
 <div className='my-auto mx-auto bg-dasRed w-10/12 flex justify-center rounded-lg'>
 
  <PrimaryButtonIcon
  buttonText={primary[0].buttonText}
  buttonIcon={primary[0].buttonIcon}
  /> 
 </div>
 </div>
 <div className='my-auto mx-auto hidden sm:block'>
 <PrimaryButtonIcon
 buttonText={primary[0].buttonText}
 buttonIcon={primary[0].buttonIcon}
 /> 
</div>
 </div>
 ))}
</SectionContainer>
 </article>
</SectionContainer>
  )
}

export default Clients
