import React from 'react'
import { ContainerInside, SectionContainer } from '../../components/sections/Container'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
import { tandc } from '../../data/termsofue'
import Footer from '../../components/footer/Footer'
import GTM from '../../googletagmanager/GTM'
import { TandCHelmet } from '../../preclicktags/PreClick'
import { FloatingButton } from '../../components/buttons/floating-button/FloatingButton'

const TandC = () => {
  return (
    <div>
      <GTM/>
      <TandCHelmet/>
      <DefaultNav/>
      <FloatingButton/>
<ContainerInside>
  <SectionContainer>

<>
<h1> Terms and Conditions</h1>
<div className='pt-6'>
{tandc.map((val)=>(
    <p className='lg:pt-4 pt-2'>{val.text}</p>
 ))}
</div>
</>


  </SectionContainer>
  </ContainerInside>
 <Footer/>
    </div>
  )
}

export default TandC
