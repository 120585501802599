import React from 'react'
import { SectionContainer } from '../../components/sections/Container'
import CTA from '../contact/CTA'
import ContactForm from '../contact/ContactForm'

const HomeContact = () => {
  return (
    <div id='home-contact' className='relative z-8  w-full'>
        <SectionContainer>
        <h2 class="relative inline-block py-2  "> Go from fast to faster</h2>
        <div class="border-b-2 border-dasBlack lg:w-1/12 w-2/6 sm:w-2/12"></div>
        <p className='py-3 lg:py-3 hidden sm:block'>If you have any question or would like to know more about our car tuning services, please fill out the following contact form and we will contact you right away!</p>
        <div className="flex  items-stretch  gap-y-8 pt-4  md:pt-8 flex-col lg:flex-row md:gap-x-8 ">
        <div class="flex-grow w-full lg:w-1/2 sm:px-16 px-0 lg:px-0">
        <CTA/>
        </div>
      <div class="flex-grow w-full lg:w-1/2 sm:px-16 px-0 lg:px-0">
      <ContactForm/> 
      </div>
      </div>
      </SectionContainer> 
    </div>
  )
}

export default HomeContact
