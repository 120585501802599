import React from 'react'
import Banner from './Banner'
import { BrandsContainer, SectionContainer } from '../../../components/sections/Container'

const IntroMob = ({title, desc,alt2,title2, image2, desc2, image1, alt1} ) => {
  return (
    <div className='block lg:hidden'>
      <Banner
      image1={ image1}
      alt1={ alt1}/>
    <SectionContainer>
        <BrandsContainer>
 <h1>{title}</h1>
 <p className='pt-2 lg:pt-4'>{desc}</p>
 </BrandsContainer>
 </SectionContainer>

 <SectionContainer>
        <BrandsContainer>

<img src={image2} alt={alt2}
className='sm:h-80 box-shadow-main-banner  object-cover w-full justify-center rounded-[16px]'/>            
 <h2 className='pt-6 lg:pt-4'>{title2}</h2>
<p className='pt-2 lg:pt-4'>{desc2}</p>
 </BrandsContainer>
 </SectionContainer>
 

    </div>
  )
}

export default IntroMob
