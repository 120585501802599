import React from "react";
import HomeNav from "../../components/navbar/HomeNav";
import { hero } from "../../data/home";
import {Container} from "../../components/sections/Container";
import { useMediaQuery } from 'react-responsive';
 
const Hero =({ targetDivRef }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const handleClick = () => {
    const targetDiv = targetDivRef.current;
    const targetTop = targetDiv.offsetTop;
    const targetHeight = targetDiv.offsetHeight;
    const windowHeight = window.innerHeight;
    const offset = Math.floor((targetHeight - windowHeight) * 0.001) - 40;
    window.scrollTo({
      top: targetTop + offset,
      behavior: "smooth",
    });
  };
  return (
    <div className="relative z-10 h-33 lg:h-screen w-full">
      <HomeNav />  
      {hero.map((val) => (
<>
<div
          className="top-0 left-0 w-full h-full bg-cover bg-center object-cover"
          style={{
            backgroundImage: `url(${isMobile ? hero[0].banner_mob : hero[0].banner_desk})`
             }}
             role="img"
             aria-label= {hero[0].alt}
        >
           <Container>
            <div className="text-normalWhite z-8 w-4/5 md:w-8/12 xl:w-6/12 3xl:bottom-14 3xl:w-5/12 4xl:w-4/12 4xl:bottom-32 lg:bottom-16 sm:bottom-14 md:bottom-16 absolute bottom-3 flex flex-col">
            <h1>{val.text1}<br />{val.text2}</h1>
              <h3 className="pt-2 hidden sm:block">{val.text3}</h3>
            </div>
          </Container>
          <div className="absolute hidden md:block  bottom-0 left-1/2 transform -translate-x-1/2 mb-2 xl:mb-2">
            <button  onClick={handleClick} className="bg-transparent text-normalWhite rounded-full py-2 px-4 shadow-lg   font-bold">
              <h2>{val.icon}</h2>
            </button>
          </div>
        </div>

</>
      ))}
    </div>
  );
};



export default Hero;
