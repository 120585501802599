export const otherBrands=[{
    title:"Brands",
    desc:"At Das Tuning, our team of highly skilled technicians specializes in tuning and enhancing the performance of a wide range of luxury car brands. We are dedicated to delivering excellent car tuning services in Dubai and the UAE, tailored to your specific requirements. Whether you're in search of Engine Tuning, ECU Tuning, Exhaust Upgrades, or more, our team is here to cater to all your needs. Get ready to elevate your driving experience with our exceptional tuning services.",
     subtitle:"Our works",
    desc3:"While our specialization lies in renowned brands, our certified professionals are also specialized in tuning and enhancing the performance of other prestigious automotive brands, including McLaren, Porsche, Land Rover, Jaguar, Rolls Royce, Bentley, Maserati, Mini, Ferrari, Lamborghini and many more. With our bespoke car tuning solutions, you can boost your vehicle's power and improve its handling to maximize the fun and excitement of your driving adventures.",
     subtitle2:"Previous results",
    desc4:"With our excellent bespoke tuning solutions, we have successfully enhanced the performance of many prestigious automotive brands. Our team of technicians understand the unique characteristics of each brand and uses expert techniques to enhance your vehicle's performance bring exceptional results to all clients."
  }]
  export const otherBrandsBanner=[{
    img:"./Images/other-brands/das-tuning-dubai-tuning-shop-orange-lamborghini-aventador-silver-rolls-royce-maroon-lancia.png",
    img_mob:"./Images/other-brands/das-tuning-dubai-tuning-shop-orange-lamborghini-aventador-silver-rolls-royce-maroon-lancia-mob.png",
    alt:"Das Tuning in Dubai transforms vehicles like the orange Lamborghini Aventador, silver Rolls Royce, and maroon Lancia with precision and performance enhancements in our tuning shop."
  }]
export const brand_logo = [
  {
    img:"./Images/automakers-logo/aston-martin-logo.png",
    alt:"Aston Martin logo - Embrace the elegance and power of the renowned British luxury car brand."
},
    {img:"./Images/automakers-logo/audi-car-tuning-das-tuning.png",
    alt:"Experience the ultimate Audi car tuning services at Das Tuning. The iconic Audi logo representing precision, performance, and luxury.",
    },
    {
      img:"./Images/automakers-logo/bentley-logo-das-tuning.png",
      alt:"Embrace the Bentley car tuning excellence at Das Tuning. Discover the iconic Bentley logo, symbolizing luxury, performance, and craftsmanship."
},
    {img:"./Images/automakers-logo/bmw-logo-car-tuning-das-tuning.png",
    alt:"Unlock the full potential of your BMW with exceptional car tuning services at Das Tuning. Immerse in the iconic BMW logo symbolizing performance, elegance, and innovation.",
    },
    {
      img:"./Images/automakers-logo/ferrari-logo-car-tuning-das-tuning.png",
      alt:"Indulge in the unrivaled Ferrari car tuning expertise at Das Tuning. Experience the legendary Ferrari logo, a symbol of passion, speed, and automotive excellence."
},
{
    img:"./Images/automakers-logo/jaguar-logo-car-tuning-das-tuning.png",
    alt:"Indulge in extraordinary Jaguar car tuning services at Das Tuning. Behold the captivating Jaguar logo, symbolizing elegance, power, and sophistication."
},
{
  img:"./Images/automakers-logo/lamborghini-logo-das-tuning.png",
  alt:"Embark on a thrilling journey of Lamborghini car tuning expertise at Das Tuning. Witness the captivating Lamborghini logo, an emblem of power, style, and exhilaration."
},
{
  img:"./Images/automakers-logo/land-rover-logo-das-tuning.png",
  alt:"Indulge in exceptional Land Rover car tuning services at Das Tuning. Behold the distinguished Land Rover logo, a symbol of adventure, sophistication, and off-road capability."
},
{
  img:"./Images/automakers-logo/maserati-logo-car-tuning-das-tuning.png",
  alt:"Indulge in unrivaled Maserati car tuning services at Das Tuning. Witness the captivating Maserati logo, epitomizing elegance, power, and sophistication."
},
{
  img:"./Images/automakers-logo/mclaren-logo-car-tuning-das-tuning.png",
  alt:"Explore the pinnacle of car tuning with our exceptional McLaren services at Das Tuning. Behold the iconic McLaren logo, symbolizing cutting-edge technology and exhilarating performance."
},
    { img:"./Images/automakers-logo/mercedes-logo-car-tuning-das-tuning.png",
    alt:"Experience the epitome of luxury and performance with Mercedes car tuning services by Das Tuning. Embrace the iconic Mercedes logo, a symbol of elegance, power, and craftsmanship.",
    },
    {
      img:"./Images/automakers-logo/mini-logo-car-tuning-das-tuning.png",
      alt:"Discover exceptional Mini car tuning services at Das Tuning. Embrace the iconic Mini logo, a symbol of style and exhilaration."
  },
  {
      img:"./Images/automakers-logo/porsche-logo-car-tuning-das-tuning.png",
      alt:"Discover the art of Porsche car tuning excellence at Das Tuning. Immerse yourself in the timeless allure of the iconic Porsche logo, symbolizing precision and exhilaration."
  },
  {
      img:"./Images/automakers-logo/rolls-royce-logo-car-tuning-das-tuning.png",
      alt:"Experience the epitome of luxury with our Rolls-Royce car tuning services at Das Tuning. Discover the iconic Rolls-Royce logo, symbolizing timeless elegance and unparalleled craftsmanship"
  },
    {img:" ./Images/automakers-logo/volkswagen-car-tuning-das-tuning.png",
    alt:"Unlock the full potential of your Volkswagen with professional car tuning services by Das Tuning. Embrace the iconic Volkswagen logo, a symbol of reliability, innovation, and driving pleasure.",
    },
    {img:" ./Images/automakers-logo/lancia-logo-car-tuning-das-tuning.png",
    alt:"Discover the iconic Lancia logo representing performance and style at Das Tuning, Dubai's premier car tuning company ",
    },
]
export const our_works=[
    {img:"./Images/other-brands/das-tuning-dubai-matte-grey-mclaren-570s-rear-tuning-shop.png",
    alt:"Enhance your driving experience with our professional car tuning services for the matte grey McLaren 570S at the Das Tuning shop in Dubai."},
    {img:"./Images/other-brands/red-lancia-car-tuning-das-tuning.png",
    alt:" Discover the exceptional car tuning services at Das Tuning as we work on a striking red Lancia with the hood open in our professional tuning shop."},
    {img:"./Images/other-brands/silver-porsche-gt2-rs-car-tuning-das-tuning.png",
    alt:"  Experience the pinnacle of car tuning excellence at Das Tuning as we transform a stunning silver Porsche GT2 RS in our state-of-the-art tuning shop."},
    {img:"./Images/other-brands/white-mclaren-720s-car-tuning-das-tuning.png",
    alt:"Witness the exceptional car tuning expertise at Das Tuning as we elevate a pristine white McLaren 720S to new performance heights in our cutting-edge tuning shop."},
 ]
 export const our_services=[{
  title:"Services we provide",
 p:"At Das Tuning, we understand that every car enthusiast has unique requirements when it comes to tuning their vehicles. That's why we offer a wide range of car tuning services tailored to your specific needs and preferences. Our team of skilled and professional technicians are passionate about pushing the limits of automotive performance and delivering exceptional results. Here are the services we provide: "
 }]
 export const our_services_cards=[
  {
    img:"./Images/about/dyno-testing-car-tuning-das-tuning.png",
    alt:"Experience the precision of dyno testing services at Das Tuning as we optimize vehicle performance in our advanced tuning shop, with a car's hood open for inspection.",
    title:"Dyno Testing",
    desc:" We offer Dyno Testing in Dubai and the UAE. We measure performance of your car’s engine and identify issues. Through this we measure engine's horsepower, torque, and air to fuel ratio."
    },
    {
        img:"./Images/about/custom-ecu-tuning-services-das-tuning.png",
        alt:"Enhance your vehicle's performance with precision custom ECU tuning services offered by Das Tuning, optimizing engine power and efficiency for an exhilarating driving experience.",
        title:"Custom ECU Tuning",
        desc:"Modify Engine Control Unit (ECU) of your vehicle and improve horsepower, torque, and fuel efficiency with the help of our tuning experts in Dubai and the UAE. Experience optimal performance with us. "
        },
        {
            img:"./Images/about/dubai-car-tuning-exhaust-upgrades-services.png",
            alt:"Upgrade your car's performance and sound with high-quality exhaust upgrades from Das Tuning, Dubai's premier car tuning specialists, enhancing power, and unleashing a thrilling driving experience.   ",
            title:"Exhaust Upgrades",
            desc:"Experience the perfect blend of sound and power with our exhaust upgrades. Trust our team of experts to assist you in selecting the ideal exhaust system for your car. Experience exhilarating power!"
            },
 ]