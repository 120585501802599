import React from 'react'
import { BannerContainer,   } from '../../components/sections/Container'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
import img from '../../images/blogs/white-mercedes-c63s-car-tuning-das-tuning.jpg'
import imgMob from '../../images/blogs/white-mercedes-c63s-car-tuning-das-tuning-mob.jpg'
import { FloatingButton } from '../../components/buttons/floating-button/FloatingButton'
import RecentBlogs from './RecentBlogs'
import Footer from '../../components/footer/Footer'
 import GTM from '../../googletagmanager/GTM'
import { BlogHelmet } from '../../preclicktags/PreClick'
import { bloghome } from '../../data/blogs'
const BlogHome = () => {
return (
 <>
  <GTM/>
  <BlogHelmet/>
 <DefaultNav/>
 <FloatingButton/>
 <div className='bg-dasOffWhite flex flex-col min-h-screen' >
 <div className='flex-grow'>
 <BannerContainer>
<div>
  {bloghome.map((val)=>(
     <>
 <div className='hidden lg:block'>
  <img src={img} alt={val.alt} className='box-shadow-main-banner w-full h-96 lg:max-h-96 object-cover lg:rounded-[16px]'/> 
  </div>

  <div className='block lg:hidden '>
  <img src={imgMob} alt={val.alt} className='w-full sm:pt-12 md:pt-8  object-cover lg:rounded-[16px]'/> 
  </div>
 </>
))}
</div>
  </BannerContainer>
    <RecentBlogs/>
    </div>
 <Footer/>
 </div>
 </>
  )
}

export default BlogHome
