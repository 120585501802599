import React from "react";
import { useEffect, useState } from "react";
import DefaultNav from "./default-nav/DefaultNav";
import TransparentNav from "./transparent-nav/TransparentNav";

const HomeNav = () => {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    function handleScroll() {
      setScrollY(window.scrollY);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className=" ">
        {scrollY > 50 ? <DefaultNav /> : <TransparentNav />}
      </div>
    </>
  );
};

export default HomeNav;
