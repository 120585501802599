import React from 'react';
import {Container  } from '../sections/Container';
import { logo } from '../../data/components/logo';
import AllSocialButtons from '../buttons/social-buttons/AllSocialButtons';
import { copy_right, footer } from '../../data/components/footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo_Foooter from '../../images/logo/das-tuning-car-tuning-services-dubai-logo.png'
import { useEffect } from 'react';
const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (location.pathname !== '/') {
      navigate('/');
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleClickWebsite = () => {
    window.open("https://trustlinedigital.com/", "_blank");
  };
  return (
<div className='pt-8 md:pt-12 2xl:pt-20  lg:pt-16'>
<footer className="bg-black text-white bottom-0      w-full">
        <Container>
      <div className="py-12   mx-auto sm:grid sm:grid-cols-2 lg:grid-cols-4   ">
        <div className="mb-8 sm:mb-0 hidden lg:block ">
        <Link to="/" >

 {logo.map((item)=>(
    <img src={logo_Foooter} alt={item.alt} className='w-48' onClick={handleClick}
    />
 ))}
      </Link>
 <div className="sm:pe-32 md:pe-56 lg:pe-28"> <AllSocialButtons/></div>
        </div>
        { footer.map((val) => (
        <div className="mb-8 sm:mb-0  flex md:justify-between">
                    <div>
                      <h4 className='pb-3 text-dasRed'> {val.title}</h4>
                      <ul class="footer-links text-dasGray">
                        {val.items.map((link) => (
                          <li className='py-2'>
                            <a
                              href={link.to} >
                              <p class=" text-dasGray" >{link.link}</p>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                   </div>
           ))}
           <div className="mb-8 sm:mb-0 block lg:hidden justify-center  sm:justify-start">
           <Link to="/" >
 {logo.map((item)=>(
    <img src={logo_Foooter} alt={item.alt} className='w-48 mx-auto sm:mx-0'/>
 ))}
      </Link>
 <div className="sm:pe-32 md:pe-56 xs:px-12 s:px-36 sm:px-0 lg:pe-28"> <AllSocialButtons/></div>
        </div>
      </div>
 <div className="flex flex-col md:flex-row w-11/12  sm:justify-between">
{copy_right.map((val)=>(
  <>
 <small className='text-dasGrayDark pb-5'>
    {val.item1}
 </small>
  <small className='text-dasGrayDark pb-5 cursor-pointer' onClick={handleClickWebsite}>
  {val.item2}
</small>
</>
))}
 </div>
      </Container>
    </footer>
    </div>
  );
}

export default Footer;
