import React from 'react'
import { BrandsContainer, SectionContainer } from '../../components/sections/Container'
import { otherBrands, our_works  } from '../../data/otherBrands'
import "swiper/css";
import "swiper/css/free-mode";
 
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
const OurWork = () => {
  return (
    <> 
    {otherBrands.map((val)=>(
        <SectionContainer>
           <BrandsContainer>
    <h2>{val.subtitle}</h2>
    <p className='pt-2 lg:pt-4'>{val.desc3}</p>
    <div className='pt-3 hidden sm:block'> 
    <div className='  flex flex-col py-4 lg:py-0   '>
  
               <div className='flex flex-row lg:pt-4 lg:gap-x-6 gap-x-4 justify-between'>
                   <div className='w-8/12  '>
                   <img className='h-full w-full justify-center object-cover rounded-[16px]  ' 
                   src={our_works[0].img}
                   alt={our_works[0].alt}/> 
                   </div>

                   <div className='w-4/12 '>
                   <img className='h-full justify-center object-cover rounded-[16px]   ' 
                   src={our_works[1].img}
                   alt={our_works[1].alt}/> 
                   </div>
               </div> 
               <div className='flex flex-row pt-4 lg:gap-x-6 gap-x-4 justify-between'>
               <div className='w-4/12 '>
                   <img className='h-full justify-center object-cover rounded-[16px]   ' 
                  src={our_works[3].img}
                  alt={our_works[3].alt}/> 
                   </div>
                   <div className='w-8/12  '>
                   <img className='h-full w-full object-cover justify-center rounded-[16px]  ' 
                    src={our_works[2].img}
                   alt={our_works[2].alt}/> 
                   </div>
               </div> 
       </div>
    </div>

    {/**Mobile  */}
    <div className='sm:hidden block'>
  <Swiper
                      spaceBetween={0}
                      modules={[Pagination]}
                className='mt-4   '
                      breakpoints={{
                        0: {
                          slidesPerView: 1.2,
                        },
                        320:{      
                          slidesPerView: 1.4,},
                        480: {
                          slidesPerView: 1.6,
                        },
                     
                 
                      }}
                    >
                      <SwiperSlide >
                      <div className='mx-2'>
                   <img className='h-48 object-cover justify-center rounded-[16px]  ' 
                   src={our_works[0].img}
                   alt={our_works[0].alt}/> 
                   </div>
                      </SwiperSlide >
                      <SwiperSlide >
                      <div className='mx-2'>
                   <img className='h-48 object-cover justify-center rounded-[16px]   ' 
                   src={our_works[1].img}
                   alt={our_works[1].alt}/> 
                   </div>
                      </SwiperSlide>
                      <SwiperSlide >
                      <div className='mx-2'>
                   <img className='h-48 object-cover  justify-center rounded-[16px]   ' 
                  src={our_works[3].img}
                  alt={our_works[3].alt}/> 
                   </div>
                      </SwiperSlide>
                      <SwiperSlide >
                      <div className='mx-2'>
                   <img className='h-48 object-cover justify-center rounded-[16px]  ' 
                    src={our_works[2].img}
                   alt={our_works[2].alt}/> 
                   </div>
                      </SwiperSlide>
                      </Swiper>
    </div>

    </BrandsContainer>
    </SectionContainer>
       ))}
       </>
  )
}

export default OurWork
