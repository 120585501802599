import React from 'react'
import HomeScreen from './home/HomeScreen'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from './contact/Contact';
import About from './aboutus/About';
import OtherBrands from './other-brands/OtherBrands';
import ThankYou from './thank-you/ThankYou';
import Bmw from './featured-brands/Bmw';
import Volkswagen from './featured-brands/Volkswagen';
import Mercedes from './featured-brands/Mercedes';
import Audi from './featured-brands/Audi';
import NotFound from './error/NotFound';
import TandC from './terms-of-use/T&C';
import Privacy from './terms-of-use/Privacy';
import Cookie from '../components/cookie/CookiePolicy';
import CookieConsent from '../components/cookie/CookieConsent';
 import Article from './article/Article';
import BlogHome from './blogs/BlogHome';
import Sitemap from './sitemap/Sitemap';
    
const Pages = () => {
  return (
    <div className='font-poppins  bg-dasOffWhite ' >
 <CookieConsent/>
  <Router>
    <Routes> 
    <Route path="/" element= {<HomeScreen/>} />
    <Route path="/contact-us" element= {<Contact/>} />
    <Route path="/thank-you" element= {<ThankYou/>} />
    <Route path="/about-us" element= {<About/>} />
    <Route path="/car-tuning-brands" element= {<OtherBrands/>} />
    <Route path="/bmw" element= {<Bmw/>} /> 
    <Route path="/volkswagen" element= {<Volkswagen/>} /> 
    <Route path="/mercedes" element= {<Mercedes/>} /> 
    <Route path="/audi" element= {<Audi/>} /> 
    <Route path="/terms-and-conditions" element= {<TandC/>} /> 
    <Route path="/privacy-policy" element= {<Privacy/>} /> 
    <Route path="/cookie-policy" element= {<Cookie/>} />
    <Route path="/sitemap" element= {<Sitemap/>} />
    <Route path="*" element={<NotFound />} />
    <Route path="/blogs/article/:title/:id" element={<Article/>} />
    <Route path="/blogs" element={<BlogHome />}/>
    </Routes> 
  </Router>
    </div>
  )
}

export default Pages
