import React from 'react'
import {  SectionContainer} from '../../components/sections/Container'
import { introduction, introduction_cards } from '../../data/home'
import "swiper/css";
import "swiper/css/free-mode";
 import './border/border.css'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
const Introduction = ({ targetDivRef }) => {
  return (
    <div ref={targetDivRef}>
    <SectionContainer >
      <section className='flex xl:flex-row flex-col justify-between' >
      <div className='xl:w-7/12'>
      <h2 class="relative inline-block">{introduction[0].title}</h2>
      <div class="border-b-2 border-dasBlack  lg:w-1/12 w-2/6 sm:w-2/12"></div>
      <p className='py-3 lg:py-3'>{introduction[0].desc}</p>
      <div>
      <img src={introduction[0].img} alt={introduction[0].alt} 
      className=' my-3 lg:my-12 xl:my-12 2xl:my-16  scale-125 xl:scale-150 h-full w-full xs:-ml-20 s:-ml-32  sm:-ml-40 md:-ml-60 lg:-ml-72 xl:-ml-24'/>
        </div>
        </div>
                      <div className='xl:w-4/12'>
                      <div className='block xl:hidden'>
                      <Swiper
                      spaceBetween={0}
                      modules={[Pagination]}
                      className="  "
                      breakpoints={{
                        0: {
                          slidesPerView: 1.3,
                        },
                        480: {
                          slidesPerView: 1.8,
                        },
                        768: {
                          slidesPerView: 2.5,
                          centeredSlides: false,
                        },
                       992: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                      }}
                    >
                      {introduction_cards.map((val)=>(
                        <>
                        <SwiperSlide className='mt-6 px-1 md:px-3 sm:px-2'>
                        <div className='flex flex-col   text-center '>
                        <div className="card bg-dasBlackTrans text-start xs:h-44 s:h-40  sm:h-40 md:h-44 lg:h-48 border-red-500   text-normalWhite p-5 my-2 rounded-[16px] "> 
 
                            <h3 className='text-dasRed'>{val.title}</h3>
                            <small>{val.desc}</small>   
                     
                             <div class="top-right corner"></div>
                            <div class="bottom-left corner"></div>                    
                            </div>
                      </div> 
                        </SwiperSlide>
                        </>
                      ))}
                      </Swiper>
                      </div> 
                      <div className='xl:block hidden'>
                     <div className='flex flex-col justify-center text-center '>
                     {introduction_cards.map((val)=>(
                      <>
                      <div className="flex flex-row">
                        <div className=" my-8 mx-2 px-3 w-12 hidden lg:block">  
                        <small className=' text-5xl text-dasRed font-semibold'>
                          {val.icon}</small>
                          </div>
                      <div className="card h-40 bg-dasBlackTrans w-full flex align-middle justify-center text-normalWhite p-5 my-3 rounded-[16px] "> 
                            <div className='flex flex-col align-middle'>
                            <h3 className='text-dasRed text-start'>{val.title}</h3>
                            <small className='text-start'>{val.desc}</small> 
                            </div>
                            <div class="top-right corner"></div>
                            <div class="bottom-left corner"></div>   
                          </div>
                        </div>
                        </>
                     ))}
                      </div> 
                      </div>
                    </div>
                    </section>
                    </SectionContainer>
                    </div>
                    )
                    }

export default Introduction
