import React from 'react'
import { BannerBrandsContainer, BannerContainerBrandsImage, BrandsContainer, ContainerInside, SectionContainer } from '../../components/sections/Container'
import { otherBrandsBanner } from '../../data/otherBrands'
import { useMediaQuery } from 'react-responsive';

const Banner = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  return (
  <>
        <BannerBrandsContainer>
           <BrandsContainer>
    {otherBrandsBanner.map((val)=>(
        <div className='mt-4 md:mt-4 lg:pt-8'>
        <img src={isMobile ? val.img_mob : val.img } alt={val.alt} className='w-full  h-96 md:h-96 lg:rounded-[16px] lg:h-full object-cover'/>
        </div>
    ))}
           </BrandsContainer>
           </BannerBrandsContainer>
  </>
  )
}

export default Banner
