import React from 'react'
import { about } from '../../data/about'
import { SectionContainer } from '../../components/sections/Container'

const TuningProcess = () => {
  return (
    <SectionContainer>
      {about.map((val)=>(
 <div className="flex md:flex-row flex-col gap-x-4  justify-between">
 <div className="md:w-7/12 w-full pe-1 ">
<h2 class="relative inline-block pb-2">{val.subtitle}</h2>
<p>{val.desc3}</p>
</div>
<div className="md:w-5/12 w-full   flex justify-center">
<img src={val.img2} alt={val.alt2} className='rounded-[16px] object-contain mt-6 md:mt-0 inline-block align-top'/>
</div>
    </div>

      ))}
  </SectionContainer>
  )
}

export default TuningProcess
