import React, { useRef } from 'react'
 import FeaturedBrands from './FeaturedBrands'
import Hero from './Hero'
import Introduction from './Introduction'
import TuningResult from './TuningResult'
import StartTuning from './StartTuning'
import Clients from './Clients'
import {FloatingButton} from '../../components/buttons/floating-button/FloatingButton'
import Scroll from '../../components/scroll/Scroll'
import Footer from '../../components/footer/Footer'
import HomeContact from './HomeContact'
import HomeBlog from './HomeBlog'
 import GTM from '../../googletagmanager/GTM'
import { useMediaQuery } from 'react-responsive';
import { HomeHelmet } from '../../preclicktags/PreClick'

 const HomeScreen = () => {
  const targetDivRef = useRef(null);
  const isDesktop = useMediaQuery({ minWidth: 1025 });
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
  <div className='overflow-hidden'>
      <GTM/>
      <HomeHelmet/>
  <FloatingButton/>
  <Hero targetDivRef={targetDivRef} />
<>
{isDesktop && (
       <Scroll targetDivRef={targetDivRef}/>
      )}
       {isMobile && (
    <>
<Introduction targetDivRef={targetDivRef} />
 <FeaturedBrands/>
 <TuningResult/>
 <StartTuning/>
 <HomeBlog/>
 <Clients/>
 </>
  )}
</>
  <HomeContact/>
  <Footer/>
    </div>
  )
}

export default HomeScreen
