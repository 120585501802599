import React from 'react'
import GridSection from './components/GridSection'
import { grid_section, previous_results, subSection, intro } from '../../data/featuredBrands'
import DefaultNav from '../../components/navbar/default-nav/DefaultNav'
import SubSection from './components/SubSection';
import {  PreviousResultsData} from './components/PreviousResults';
import { AudiPower, AudiTorque, graphForAudi } from '../../data/components/tuning';
import Footer from '../../components/footer/Footer';
import { brandsFloating } from '../../data/components/button';
import { BrandsFloatingDesktop } from '../../components/buttons/floating-button/BrandsFloating';
import IntroMob from './components/IntroMob';
import { MobFloatingButton } from '../../components/buttons/floating-button/FeaturedFloatingButtons';
 import IntroDesk from './components/IntroDesk';
import GTM from '../../googletagmanager/GTM'
import { AudiHelmet } from '../../preclicktags/PreClick';
const Audi = () => {
    return (
        <>
         <GTM/>
         <AudiHelmet/>
        <DefaultNav/>
        <BrandsFloatingDesktop 
         logo={brandsFloating[0].logo} 
         alt={brandsFloating[0].alt}
        brandTitle={brandsFloating[0].brandTitle}
        brandDesc={brandsFloating[0].brandDesc}
         />
    <MobFloatingButton
logo={brandsFloating[0].logowhite}
alt={brandsFloating[0].alt}
/>
<IntroDesk
  image1={intro.audi.image1}
  alt1={intro.audi.alt1}
  title1={intro.audi.title1}
  description1={intro.audi.description1}
  title2={intro.audi.title2}
  image2={intro.audi.image2}
  alt2={intro.audi.alt2}
  description2={intro.audi.description2}
  />
 
        <IntroMob
        image1={intro.audi.image1}
        alt1={intro.audi.alt1}
        title={intro.audi.title1}
        desc={intro.audi.description1}
        title2={intro.audi.title2}
        image2={intro.audi.image2}
        alt2={intro.audi.alt2}
        desc2={intro.audi.description2}
        />
        <GridSection 
          title1={grid_section.audi.title1}
          description1={grid_section.audi.description1}
          image1={grid_section.audi.image1}
          alt1={grid_section.audi.alt1}
          title2={grid_section.audi.title2}
          description2={grid_section.audi.description2}
          image2={grid_section.audi.image2}
          alt2={grid_section.audi.alt2}
        />
                  <SubSection
                    title={subSection.audi.title}
                    description={subSection.audi.description}
                    image={subSection.audi.image}
                    alt={subSection.audi.alt}
                  />
        <PreviousResultsData
        title={previous_results.audi.title}
        description={previous_results.audi.description}
        image={previous_results.audi.img}
        alt={previous_results.audi.alt}
        powerData={AudiPower}
        torqueData={AudiTorque}
        graphData={graphForAudi}/>
        <Footer/>
        </>
           )
}

export default Audi
