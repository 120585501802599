import React from "react";
import { TertiaryButtonIcon } from '../tertiary-button/TertiaryButton'
import { secondary, tertiary } from '../../../data/components/button'
 import { Container } from "../../sections/Container";
import { SecondaryButtonIcon } from "../secondary-button/SecondaryButton";

export const BrandsFloatingDesktop = ({ logo, alt, brandTitle,  brandDesc }) => {
    return (
        <Container className='relative'>
<div className={`fixed z-10 2xl:top-32 top-28 hidden lg:block lg:right-18 xl:right-24 2xl:right-56 3xl:right-96 4xl:right-110 `}>
        <div    className="  border text-center border-dasBlack  text-normalBlack lg:w-60 p-4 xl:p-1 xl:w-72 2xl:w-72  2xl:p-3 rounded-[16px] ">
        <div className="2xl:w-1/4 w-2/4 mx-auto lg:hidden 2xl:block">
        <img src={logo} alt={alt} />
        </div>
       <h3 className="pt-4  2xl:pt-3">{brandTitle}</h3>
        <p className="mx-auto lg:w-3/4 2xl:w-full text-center">{brandDesc}</p>
        <div className="2xl:pt-6 pt-4 w-3/4 mx-auto">
        <SecondaryButtonIcon buttonText={secondary[3].buttonText}/> 
        </div>
            <div className=" lg:pt-4 2xl:pt-6 pt-2 flex justify-center">
        <button>
         <a
            href="tel:+971-50-515-6697">
            <TertiaryButtonIcon buttonText={tertiary[0].buttonText}
            buttonIcon={tertiary[0].buttonIcon}
            />
            </a>
        </button>
        </div>
        </div>
      </div>
      </Container>
  )
}

