export const links =[
    {text:"Home", onclick:"/"},
    {text:"About", onclick:"/about-us"},
    {text:"Contact Us", onclick:"/contact-us"},
    {text:"Blogs", onclick:"/blogs"},
    {text:"BMW", onclick:"/bmw"},
    {text:"Volkswagen", onclick:"/volkswagen"},
    {text:"Mercedes", onclick:"/mercedes"},
    {text:"Audi", onclick:"/audi"},
    {text:"Car Tuning Brands", onclick:"/car-tuning-brands"},
    {text:"Terms and Conditions", onclick:"/terms-and-conditions"},
    {text:"Privacy Policy", onclick:"/privacy-policy"},
    {text:"Cookie Policy", onclick:"/cookie-policy"},
]