import React, { useState, useEffect  } from 'react';
import { useMediaQuery } from 'react-responsive'
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import { tuning_result_images } from '../../../data/home'
import './carousel.css';
 import   { GraphHome } from '../../../components/TuningResult/Graph';
import { TorqueHome } from '../../../components/TuningResult/Torque';
import { PowerHome } from '../../../components/TuningResult/Power';
import {MercedesPower,AudiPower, VolkswagenPower, BmwPower, AudiTorque, MercedesTorque, BmwTorque, VolkswagenTorque, graphForMercedes,graphForVolkswagen, graphForAudi, graphForBMW} from '../../../data/components/tuning';
import img1_desktop from '../../../images/home/results/white-mercedes-c63s-car-tuning-das-tuning.png'
import img2_desktop from '../../../images/home/results/red-bmw-m4-car-tuning-garage-das-tuning.png'
import img3_desktop from '../../../images/home/results/white-volkswagen-gti-clubsport-car-tuning-das-tuning.png'
import img4_desktop from '../../../images/home/results/matte-grey-audi-rs3-car-tuning-das-tuning.png'
import img5_desktop from '../../../images/home/results/red-bmw-m4-car-tuning-garage-das-tuning.png'
import img1_mob from '../../../images/home/results/white-mercedes-c63s-car-tuning-das-tuning-mob.png'
import img2_mob from '../../../images/home/results/red-bmw-m4-car-tuning-garage-das-tuning-mob.png'
import img3_mob from '../../../images/home/results/white-volkswagen-gti-clubsport-car-tuning-das-tuning-mob.png'
import img4_mob from '../../../images/home/results/matte-grey-audi-rs3-car-tuning-das-tuning-mob.png'
import img5_mob from '../../../images/home/results/white-mercedes-c63s-car-tuning-das-tuning-mob.png'

 const content = [
  "Mercedes C63",
   "BMW M2",
  "Volkswagen GTI Clubsport",
  "Audi RS3",
 ];
const slideData = [
  { power: MercedesPower, torque: MercedesTorque, graph: graphForMercedes },
  { power: BmwPower, torque: BmwTorque, graph: graphForBMW },
  { power: VolkswagenPower, torque: VolkswagenTorque, graph: graphForVolkswagen },
  { power: AudiPower, torque: AudiTorque, graph: graphForAudi },
 
];
export default function Carousel() {
  const images = [img1_desktop, img2_desktop, img3_desktop, img4_desktop];
  const isDesktopOrLaptop = useMediaQuery({
   query: '(min-device-width: 768px)'
 })
 const isMobile = useMediaQuery({
   query: '(max-width: 767px)'
 })
 const [activeIndex, setActiveIndex] = useState(0);
 const nextSlide = () => {
   setActiveIndex((activeIndex + 1) % images.length);
 };
 const prevSlide = () => {
   setActiveIndex((activeIndex - 1 + images.length) % images.length);
 };
 const [opacity, setOpacity] = useState(0);
  
  useEffect(() => {
    setOpacity(0);
    const timer = setTimeout(() => setOpacity(1), 50);
    return () => clearTimeout(timer);
  }, [activeIndex]);
return (
 <>
{tuning_result_images.map((val)=>(
<div class="mkt-3dSlider  relative  ">  
<div class="  p-2 sm:p-8 md:p-10 lg:p-12 xl:p-16 relative  ">
 {/**top arrow */}
<div className='absolute top-0 left-0 right-0 flex justify-center'>
<button className='text-dasBlack absolute -mt-16 sm:-mt-14 xl:-mt-10 'onClick={nextSlide}>
<h3> <BsChevronUp/></h3>
 </button>
</div>
{tuning_result_images.map((val)=>(
<section id="slider-playlist">
 <input class="card-slider" type="radio" name="slider" id="s1" checked={activeIndex === 0}/>
 <input class="card-slider" type="radio" name="slider" id="s2" checked={activeIndex === 1}/>
 <input class="card-slider" type="radio" name="slider" id="s3" checked={activeIndex === 2}/>
 <input class="card-slider" type="radio" name="slider" id="s4" checked={activeIndex === 3}/>
 <input class="card-slider" type="radio" name="slider" id="s5" checked={activeIndex === 4}/>
 <label for="s1" id="slide1" class="card-slider  rounded-xl">
 <img src={isDesktopOrLaptop ? img1_desktop : img1_mob} alt={val.alt1} className='md:h-96 sm:h-80 h-72 rounded-xl object-cover'/>
 </label>
 <label for="s2" id="slide2" class="card-slider  rounded-xl ">
 <img src={isDesktopOrLaptop ? img2_desktop : img2_mob }   alt={val.alt2} className='md:h-96 sm:h-80 h-72 rounded-xl object-cover'/>
 </label>
 <label for="s3" id="slide3" class="card-slider rounded-xl">
 <img src={isDesktopOrLaptop ? img3_desktop : img3_mob}  alt={val.alt3} className='md:h-96 sm:h-80 h-72  rounded-xl object-cover'/>
 </label>
 <label for="s4" id="slide4" class="card-slider  rounded-xl">
 <img src={isDesktopOrLaptop ? img4_desktop : img4_mob}  alt={val.alt4} className='md:h-96 sm:h-80 h-72 rounded-xl object-cover'/>
 </label>
 <label for="s5" id="slide5" class="card-slider  rounded-xl ">
 <img src={isDesktopOrLaptop ? img5_desktop : img5_mob}   alt={val.alt5} className='md:h-96 sm:h-80 h-72  rounded-xl object-cover'/>
 </label>
</section> 
))}
 {/**bottom arrow */}
<div className='absolute bottom-0 left-0 right-0 flex justify-center'>
<button className='text-dasBlack absolute mt-5 sm:mt-8 md:mt-10 xl:mt-6 3xl:mt-4  ' onClick={prevSlide} >
<h3><BsChevronDown/></h3> 
 </button>
 </div>
 </div>
 {/**content section */}
 <div className='absolute content-section  top-85 sm:top-auto sm:-bottom-120 lg:-bottom-130 xl:-bottom-132 px-2 justify-center'>
            <div style={{opacity: opacity, transition: 'opacity 1s'}} className='text-dasBlack  ' onClick={prevSlide} >
              <h2>{content[activeIndex]}</h2>
            </div>
            <div className='hidden lg:block'>
            <div className="flex flex-row pe-8 gap-x-8 justify-between">
            <div className='w-6/12'>
              <PowerHome data={slideData[activeIndex].power}/>
            </div>
            <div className='w-6/12'>
              <TorqueHome data={slideData[activeIndex].torque}/>
            </div>
          </div>
          <div className='w-full mx-auto pe-8'>
          <GraphHome data={slideData[activeIndex].graph} />
          </div>
          </div>
          <div className='lg:hidden block mx-auto'>
           {/**add new slider here */}
           <div className='flex flex-col sm:flex-row  mx-auto  '>
           <div className='p-1 sm:w-72 md:w-85'>
              <PowerHome data={slideData[activeIndex].power}/>
            </div>
            <div className='p-1 sm:w-72 md:w-85 '>
              <TorqueHome data={slideData[activeIndex].torque}/>
            </div></div>
            <div className='w-full mx-auto p-1'>
          <GraphHome data={slideData[activeIndex].graph} />
          </div>
           
                      </div>
          </div>
</div>
))}
</>
 );
}

 

