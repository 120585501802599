import React from 'react'
import { BrandsContainer, SectionContainer } from '../../../components/sections/Container'

const SubSection = ({ title, description, image, alt  }) => {
    return (
        <>
        <SectionContainer>
        <BrandsContainer>
         <div className='flex flex-col '>
            <div className=' pt-6 lg:pt-0 w-full order-last lg:order-first'>
            <h3>{title}</h3>
            <p className='pt-2 lg:pt-4'>{description}</p>
            </div>
            <div className=' lg:pt-8 lg:pe-3 xl:pe-0 w-full order-first lg:order-last'>
            <img src={image} alt={alt} 
            className='h-80 lg:h-96 sm:h-80 box-shadow-main-banner  object-cover w-full justify-center rounded-[16px]'/>
            </div>
        </div>
        </BrandsContainer>
          </SectionContainer>
          
        
          </>
      )
}

export default SubSection
